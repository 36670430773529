<script setup lang="ts">
// components
import CiCampsiteDetailProposeChange from '@/components/campsitedetail/CiCampsiteDetailProposeChange/CiCampsiteDetailProposeChange.vue';
import CiCampsiteEquipmentList from '@/components/campsitedetail/CiCampsiteSectionEquipment/CiCampsiteEquipmentList/CiCampsiteEquipmentList.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionEquipmentProps {
  equipment: any;
}
const props = withDefaults(defineProps<CiCampsiteSectionEquipmentProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const viewport = useViewport();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const equipmentCount = ref(0);
const uncollapsedList = ref<number | null>(null);

const mappedEquipment = props.equipment.map((category) => {
  // transform to array
  const dataAsArray = Object.entries(category.data);

  // filter available items
  const available = dataAsArray.filter(
    ([_, value]) =>
      (value !== null
        && ((typeof value === 'boolean' && value)
          || (typeof value === 'number' && value > 0)
          || (typeof value === 'object' && value.value !== -1 && value.value !== null)))
          || (typeof value === 'string' && value !== ''),
  );

  // filter unavailable items
  const unavailable = dataAsArray.filter(
    ([_, value]) =>
      value !== null
      && ((typeof value === 'boolean' && !value)
        || (typeof value === 'number' && !value)
        || (typeof value === 'object' && value.value === -1)),
  );

  // transform to object
  category.dataAvailable = { ...Object.fromEntries(available) };
  category.dataUnavailable = { ...Object.fromEntries(unavailable) };

  // calc total displayed equipments
  equipmentCount.value
      = equipmentCount.value + Object.keys(category.dataAvailable).length + Object.keys(category.dataUnavailable).length;
  return category;
});

/********************
 * FUNCTIONS         *
 ********************/
function onToggleAccordion(index: number) {
  uncollapsedList.value = uncollapsedList.value === index ? null : index;
}
</script>

<template>
  <section>
    <h2
      key="section-headline-equipment"
      class="px-6 text-center text-black"
    >
      {{ $gettext('Properties') }}
    </h2>

    <div class="mt-6 lg:px-[15px] lg:py-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <template v-for="(item, index) in mappedEquipment">
              <template
                v-if="
                  Object.getOwnPropertyNames(item.dataAvailable).length
                    || Object.getOwnPropertyNames(item.dataUnavailable).length
                "
              >
                <CiAccordion
                  :key="`equipment-${index}`"
                  :show="uncollapsedList === index"
                  :mq="'lg'"
                  class="equipment__accordion mt-0 lg:mt-6"
                  layout="primary"
                  :scroll-to-item="false"
                  :is-last="index === equipment.length - 1"
                  @accordion-toggle="viewport.isLessThan('lg') && onToggleAccordion(index)"
                >
                  <template #header>
                    <h3 class="my-0 py-4 lg:mt-12 lg:py-0">
                      {{ item.title }}
                    </h3>
                    <hr class="my-4 hidden h-px border-0 bg-gray-40 lg:block" />
                  </template>
                  <div class="my-4 lg:my-0">
                    <!-- available -->
                    <CiCampsiteEquipmentList
                      v-if="Object.getOwnPropertyNames(item.dataAvailable).length"
                      :items="item.dataAvailable"
                    />

                    <!-- not available available -->
                    <CiCampsiteEquipmentList
                      v-if="Object.getOwnPropertyNames(item.dataUnavailable).length"
                      :items="item.dataUnavailable"
                      class="mt-4"
                    />
                  </div>
                </CiAccordion>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="equipmentCount === 0"
        class="text-center"
      >
        <span>{{ $gettext('The campsite has not yet specified any properties.') }}</span>
      </div>
    </div>

    <div class="container mt-12">
      <div :class="{ container: viewport.isGreaterThan('md') }">
        <hr class="my-4 h-px border-0 bg-gray-40" />
        <CiCampsiteDetailProposeChange data-tracking-id="equipment-suggest-change" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.equipment__accordion {
  :deep(.accordion__header) {
    @apply lg:border-0;
    .container {
      @include media-breakpoint-up(lg) {
        padding: 0 30px;
      }
    }
  }
  &.accordion.open {
    :deep(.accordion__body) {
      border-bottom: 1px solid $gray-500 !important;

      @include media-breakpoint-up(lg) {
        border-bottom: 0px solid $gray-500 !important;
      }
    }
  }

  &.accordion:last-of-type {
    :deep(.accordion__header) {
      border-bottom: 0px solid $gray-500 !important;
    }
  }
}
</style>
