<script setup lang="ts">
// icons
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

// components
import CiAvailabilityInfo from '@/components/tiles/snippets/CiAvailabilityInfo.vue';
import CiLoadMore from '@/components/tiles/snippets/CiLoadMore.vue';
import CiTileImageSlider from '@/components/tiles/snippets/CiTileImageSlider.vue';

// utils
import dayjs from '@/utils/day';

import { accommodationProps } from '@/locales/fixtures';

const NEW_FLEX_ITEMS_TO_SHOW = 3;

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiAccommodationTileProps {
  campsite: any;
  accommodation: any;
  checkAvailabilitiesButton?: boolean;
  snowplowContext?: any;
}
const props = withDefaults(defineProps<CiAccommodationTileProps>(), {
  checkAvailabilitiesButton: false,
  snowplowContext: () => {},
});

const emit = defineEmits<{
  'check-availabilities': [];
  'show-details': [value: any];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const route = useRoute();
const viewport = useViewport();
const { $gettext, $pgettext } = useGettext();
const { i18nNoExactAvailabilities } = useI18nSearchAvailabilities();
const { impressionId, trackAdImpression, trackAdClick } = useTracking();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const availabilityPosition = ref(0);
const contexts = shallowRef([]);
const flexVisibleCount = ref(3);
const hasOpenedFlex = ref(false);
const showFlexAvailabilities = ref(false);

const isMobileView = computed(() => {
  return viewport.isLessThan('md');
});

const exactAvailabilities = computed(() => {
  return getAvailabilities(true);
});

const hasExactAvailabilities = computed(() => {
  return exactAvailabilities.value.length > 0;
});

const flexAvailabilities = computed(() => {
  return getAvailabilities(false);
});

const hasFlexAvailabilities = computed(() => {
  return flexAvailabilities.value.length > 0;
});

onMounted(() => {
  // for tracking
  addBaseContexts();
  addExactAvailabilityContexts();
});

/********************
 * FUNCTIONS         *
 ********************/
function getAvailabilities(exact = true) {
  if (!props.accommodation.availabilities || props.accommodation.availabilities.length === 0) {
    return [];
  }
  const avs = [];
  props.accommodation.availabilities.forEach((av) => {
    let condition;
    if (exact) {
      condition = av.start_date === route.query.from && av.end_date === route.query.until;
    } else {
      condition = av.start_date !== route.query.from || av.end_date !== route.query.until;
    }
    if (condition) {
      avs.push(av);
    }
  });
  return avs;
}

function isVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.snowplowContext.bannerId,
      impressionId: impressionId.value,
      targetUrl: props.campsite.booking_url,
      zoneId: props.snowplowContext.zoneId,
      context: contexts.value,
    });
  }
}

function getFormattedDate(date) {
  if (!date) {
    return;
  }
  return dayjs(date).format('DD.MM.YY');
}

function fixSpaceBeforeColon(stringFromCtoutvert) {
  if (locale.value === 'fr') {
    return stringFromCtoutvert;
  }
  return stringFromCtoutvert.replace(/\s+:\s+/, ': ');
}

function getPropertyTranslation(prop) {
  if (props.campsite.booking_provider === 'phobs') {
    return prop.name || prop.code;
  }
  if (props.campsite.booking_provider === 'camping.care') {
    const translation = accommodationProps[prop.code];
    if (translation) {
      return $pgettext(translation.ctx, translation.key);
    } else {
      return prop.name || prop.code;
    }
  }
  // We will use the translations from C'toutvert for now. See comment in `fixtures.js` for details.
  if (prop.name) {
    if (locale.value !== 'en') {
      if (prop.name === 'Max persons') {
        return $pgettext('accommodation property maximumPersons', prop.name);
      }
      if (prop.name === 'Persons included') {
        return $pgettext('accommodation property personsIncluded', prop.name);
      }
    }
    return fixSpaceBeforeColon(prop.name);
  }
  return prop.code;
}

function getAccommodationNameSingular(type) {
  if (type === 'acco') {
    return $pgettext('Availability tile accommodation type acco', 'Rental');
  }
  if (type === 'pitch') {
    return $pgettext('Availability tile accommodation type pitch', 'Pitch');
  }
}

function toggleFlexAvailabilities() {
  showFlexAvailabilities.value = !showFlexAvailabilities.value;

  if (!hasOpenedFlex.value) {
    hasOpenedFlex.value = true;
    addFlexContextsAndTrack(0, NEW_FLEX_ITEMS_TO_SHOW);
  }
}

function showMoreFlexAvailabilities() {
  flexVisibleCount.value = flexVisibleCount.value + NEW_FLEX_ITEMS_TO_SHOW;
  addFlexContextsAndTrack(flexVisibleCount.value - NEW_FLEX_ITEMS_TO_SHOW, flexVisibleCount.value);
}

function showAccommodationDetails() {
  const id = props.accommodation.accommodation_id || props.accommodation.id;

  emit('show-details', {
    campsite: props.campsite,
    id,
    snowplow: {
      ...props.snowplowContext,
      context: contexts.value,
      impressionId: impressionId.value,
    },
    availabilities: {
      exactAvailabilities: exactAvailabilities.value,
      flexAvailabilities: flexAvailabilities.value,
    },
  });
}

function addBaseContexts() {
  contexts.value = [
    {
      schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
      data: {
        booked_products: toRaw(props.campsite.purchases) || null,
        camping_id: props.campsite.camping_id,
        civ2_id: props.campsite.id,
        slug: props.campsite.slug,
      },
    },
    {
      schema: 'iglu:com.camparound/campinginfo_campsite_accommodation/jsonschema/1-0-0',
      data: {
        accommodation_id: props.accommodation.accommodation_id || props.accommodation.id,
        accommodation_name: props.accommodation.name,
        accommodation_type: props.accommodation.acco_type,
        position: props.snowplowContext.position,
        provider: props.campsite.booking_provider,
      },
    },
  ];
}

function addExactAvailabilityContexts() {
  if (hasExactAvailabilities.value) {
    exactAvailabilities.value.forEach((av) => {
      av.choices.forEach((choice) => {
        availabilityPosition.value++;
        addContext({
          schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/3-0-0',
          data: {
            accommodation_type: props.accommodation.acco_type,
            availability_type: 'exact',
            base_price: choice.basePrice,
            critical_stock: choice.stock <= 5,
            currency: choice.currency,
            duration: av.duration,
            end_date: av.end_date,
            position: availabilityPosition.value,
            price: choice.price,
            start_date: av.start_date,
            stock: choice.stock,
            type: choice.type,
          },
        });
      });
    });
  }
}

function addContext(context: any) {
  contexts.value = [...toRaw(contexts.value), context];
}

function addFlexContextsAndTrack(begin, end) {
  flexAvailabilities.value.slice(begin, end).forEach((av) => {
    av.choices.forEach((choice) => {
      availabilityPosition.value++;
      addContext({
        schema: 'iglu:com.camparound/campinginfo_campsite_availability/jsonschema/3-0-0',
        data: {
          accommodation_type: props.accommodation.acco_type,
          availability_type: 'flex',
          base_price: choice.basePrice,
          critical_stock: choice.stock <= 5,
          currency: choice.currency,
          duration: av.duration,
          end_date: av.end_date,
          position: availabilityPosition.value,
          price: choice.price,
          start_date: av.start_date,
          stock: choice.stock,
          type: choice.type,
        },
      });
    });
  });

  // track
  trackAdClick({
    targetUrl: 'show-more',
    bannerId: props.snowplowContext.bannerId,
    zoneId: `show-more-${end}`,
    impressionId: impressionId.value,
    context: contexts.value,
  });
}
</script>

<template>
  <div
    v-observe-visibility="{
      callback: isVisible,
      once: true,
      throttle: 500,
      intersection: {
        threshold: 0.7,
      },
    }"
  >
    <div
      class="h-auto overflow-hidden rounded-lg border-gray-20 bg-white md:h-[14.4375rem] lg:h-[12.6875rem] xl:h-[15.1875rem]"
      :class="[
        hasExactAvailabilities || hasFlexAvailabilities
          ? 'rounded-b-none border border-b-0'
          : 'border',
      ]"
    >
      <div class="grid h-full grid-cols-3">
        <div class="col-span-3 h-full md:col-span-1">
          <CiTileImageSlider
            :images="props.accommodation.media && props.accommodation.media.images"
            :desktop-use-square-images="!isMobileView"
          />
        </div>
        <div class="relative col-span-3 h-full md:col-span-2">
          <div class="ml-0 flex h-full flex-col p-2 md:ml-2">
            <!-- type -->
            <div class="text-sm text-gray-light">
              {{ getAccommodationNameSingular(props.accommodation.acco_type) }}
            </div>

            <!-- name -->
            <div class="mt-1 line-clamp-2 text-lg font-medium leading-6">
              {{ props.accommodation.name }}
            </div>

            <!-- properties -->
            <div class="overflow-x-hidden md:line-clamp-3 xl:line-clamp-4">
              <ul
                v-if="props.accommodation.properties && props.accommodation.properties.length > 0"
                class="accommodation-tile__properties m-0 ml-[-7px] mt-2 list-none p-0 text-sm leading-5 text-gray"
              >
                <li
                  v-for="(property, idx) in props.accommodation.properties"
                  :key="`${property.code}-${idx}`"
                  class="inline-block truncate border-l px-2 py-0 leading-[14px]"
                >
                  <!-- <div class="truncate"> -->
                  <span>{{ getPropertyTranslation(property) }}</span>
                  <span v-if="property.value !== true">: {{ property.value }}</span>
                  <!-- </div> -->
                </li>
              </ul>
            </div>

            <!-- show details -->
            <button
              type="button"
              class="button button--text my-2 self-start p-0"
              @click="showAccommodationDetails"
            >
              <span class="text-primary">{{ $gettext('show details') }}</span>
            </button>

            <!-- check availabilities -->
            <button
              v-if="checkAvailabilitiesButton"
              type="button"
              class="button button--primary mt-3 md:absolute md:bottom-2 md:right-2"
              :class="{ 'w-full': isMobileView }"
              @click="emit('check-availabilities')"
            >
              <span>{{ $gettext('Check availability') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- no exact match info -->
    <div
      v-if="!hasExactAvailabilities && hasFlexAvailabilities"
      class="flex items-center border border-gray-20 bg-white p-4 text-xs text-info/90"
    >
      <CiAwesomeIcon
        class="mr-4 fill-info"
        :icon="faExclamationCircle"
        ratio="0.6"
      />
      {{ i18nNoExactAvailabilities(getFormattedDate(route.query.from), getFormattedDate(route.query.until)) }}
    </div>

    <!-- exact availabilities -->
    <div
      v-if="hasExactAvailabilities"
      class="border border-gray-20 bg-white"
      :class="{ 'rounded-b-lg': !hasFlexAvailabilities }"
    >
      <div
        v-for="(av, idx) in exactAvailabilities"
        :key="idx"
        class="accommodation-tile__availability px-2 py-4"
      >
        <CiAvailabilityInfo
          v-for="(choice, idx2) in av.choices"
          :key="idx2"
          :campsite="props.campsite"
          :availability="{
            ...choice,
            start_date: av.start_date,
            end_date: av.end_date,
            duration: av.duration,
            acco_type: accommodation.acco_type,
          }"
          :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_exact-date', contexts, impressionId }"
          primary
        />
      </div>
    </div>

    <!-- flex toggle -->
    <div
      v-if="hasFlexAvailabilities"
      class="user-select-none flex cursor-pointer items-center justify-center border border-t-0 border-gray-20 bg-white p-2 text-sm text-primary"
      :class="[showFlexAvailabilities ? 'rounded-b-none' : 'rounded-b-lg']"
      @click="toggleFlexAvailabilities"
    >
      <span
        v-if="!showFlexAvailabilities"
        key="show"
      >{{ $gettext('Show similar travel periods') }}</span>
      <span
        v-else
        key="hide"
      >{{ $gettext('Hide similar travel periods') }}</span>
      <div
        class="rotate-0 transition-transform"
        :style="[showFlexAvailabilities ? 'transform: rotateX(-180deg);' : '']"
      >
        <CiAwesomeIcon
          :icon="faChevronDown"
          class="ml-2 fill-primary"
          ratio="0.6"
        />
      </div>
    </div>

    <!-- flex availabilities -->
    <div
      v-if="hasFlexAvailabilities && showFlexAvailabilities"
      class="rounded-b-lg border border-t-0 border-gray-20 bg-white"
    >
      <div
        v-for="(av, idx) in flexAvailabilities.slice(0, flexVisibleCount)"
        :key="`flex_${idx}`"
        class="px-2 py-4"
        :class="{ 'border-b border-gray-20': idx + 1 !== flexVisibleCount }"
      >
        <CiAvailabilityInfo
          v-for="(choice, idx2) in av.choices"
          :key="`flex_${idx2}`"
          :campsite="props.campsite"
          :availability="{
            ...choice,
            start_date: av.start_date,
            end_date: av.end_date,
            duration: av.duration,
            acco_type: accommodation.acco_type,
          }"
          :snowplow-context="{ ...props.snowplowContext, zone: 'campsite_flex-date', contexts, impressionId }"
        />
      </div>

      <!-- flex load more -->
      <CiLoadMore
        :visible-count="flexVisibleCount"
        :count="flexAvailabilities.length"
        @show-more="showMoreFlexAvailabilities"
      />
    </div>
  </div>
</template>

<style></style>
