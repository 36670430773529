<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

// components
import CiReviewSlide from '@/components/CiReview/CiReviewSlide.vue';
import CiReviewSlideMore from '@/components/CiReview/CiReviewSlideMore.vue';
import CiSkeletonReviewSlider from '@/components/skeleton/CiSkeletonReviewSlider.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiReviewSliderProps {
  campsiteSlug: string;
  initialHasReviews?: boolean;
}
const props = withDefaults(defineProps<CiReviewSliderProps>(), {
  initialHasReviews: true,
});

/********************
 * COMPOSITIONS      *
 ********************/
// const route = useRoute();
const localePath = useLocalePath();
const reviewService = useReviewService();
const { $gettext } = useGettext();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const hasReviews = ref(props.initialHasReviews);
const limit = ref(9);

const user = computed(() => {
  const additionalReviewCount = data.value?.results?.length - reviewCount.value;
  const users = [];

  for (let i = 0; i < additionalReviewCount; i += 1) {
    const image = data.value?.results?.[reviewCount.value + i].user_profile_picture;
    users.push(image);
  }
  return users;
});

const reviewCount = computed(() => {
  if (data.value?.results?.length < 6) {
    return data.value?.results?.length;
  }
  return 6;
});

/********************
 * INITIALIZATION    *
 ********************/
const { data, pending } = await useLazyAsyncData(`reviews-${props.campsiteSlug}`, getReviews);

async function getReviews() {
  try {
    const response = await reviewService.getReviews(locale.value, props.campsiteSlug, {
      limit: limit.value,
      offset: 0,
    });
    // this.$emit('review-slider-count', reviews.count);
    hasReviews.value = !!response.results.length;
    return response;
  } catch (error) {
    return error;
  }
}
</script>

<template>
  <div
    v-if="hasReviews && data"
    class="mt-12 pt-12"
  >
    <div class="review-slider">
      <div
        v-if="!pending"
        class="container"
      >
        <div class="row">
          <div class="col-12">
            <h3
              class="text-center"
              v-html="$gettext('<strong>What guests</strong> write', true)"
            />
          </div>
        </div>
      </div>

      <div
        v-if="!pending"
        class="mt-4"
      >
        <div class="review-slider__container">
          <CiCssSlider v-if="data.results?.length">
            <div
              v-for="index in reviewCount"
              :key="data.results?.[index - 1].id"
              class="slide"
              :data-idx="index"
            >
              <CiReviewSlide
                :review="data.results?.[index - 1]"
                :campsite-id="props.campsiteSlug"
              />
            </div>
            <div
              v-if="data.results.length > reviewCount"
              class="slide"
              :data-idx="reviewCount + 1"
            >
              <CiReviewSlideMore
                :campsite-id="props.campsiteSlug"
                :count="data.results.length - reviewCount"
                :users="user"
              />
            </div>
          </CiCssSlider>
        </div>
      </div>
      <div
        v-else
        class="container"
      >
        <CiSkeletonReviewSlider />
      </div>

      <div
        v-if="hasReviews"
        class="row mt-4"
      >
        <nuxt-link
          :to="localePath({ name: 'campsite-identifier-reviews', params: { identifier: props.campsiteSlug } })"
          class="button button--primary-outline mx-auto"
        >
          <span>{{ $gettext('Read all reviews') }}</span>
          <CiAwesomeIcon
            :icon="faChevronRight"
            ratio="0.6"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.review-slider__container {
  .slide {
    flex: 1 0 300px;
    max-width: 300px;
    position: relative;

    @include media-breakpoint-up(md) {
      flex: 1 0 320px;
      max-width: 320px;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 0 300px;
      max-width: 300px;
    }

    @include media-breakpoint-up(xl) {
      flex: 1 0 360px;
      max-width: 360px;
    }
  }

  @include media-breakpoint-only(xs) {
    .slide {
      scroll-snap-align: center;
    }

    .slide:first-of-type {
      margin-left: 15px;
    }

    .slide:last-of-type {
      margin-right: 15px;
    }
  }

  @include media-breakpoint-only(sm) {
    padding: 0;
    .slide {
      scroll-snap-align: center;
    }

    .slide:first-of-type {
      margin-left: calc((100vw - 540px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 540px) / 2 + 15px);
    }
  }

  @include media-breakpoint-only(md) {
    padding: 0;

    .slide {
      scroll-snap-align: center;
    }

    .slide:first-of-type {
      margin-left: calc((100vw - 720px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 720px) / 2 + 15px);
    }
  }

  @include media-breakpoint-only(lg) {
    max-width: 930px;
    margin: auto;

    .slide {
      scroll-snap-align: start;
    }
  }

  @include media-breakpoint-only(xl) {
    margin: auto;
    max-width: 1110px;

    .slide {
      scroll-snap-align: start;
    }
  }
}
</style>
