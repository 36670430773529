<script setup lang="ts">
import { equals, path as RamdaPath } from 'ramda';

// icons
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import translationFixtures from '@/locales/fixtures';

import dayjs from '@/utils/day';

// components
import CiSearch from '@/components/CiSearch/CiSearch.vue';
import CiSkeletonCampsiteDetail from '@/components/skeleton/CiSkeletonCampsiteDetail.vue';
import CiStickyNavigation from '@/components/CiStickyNavigation/CiStickyNavigation.vue';

definePageMeta({
  layout: 'default',
  middleware: ['numeric-redirect'],
});

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const backendStore = useBackendStore();
const campsiteStore = useCampsiteStore();
const getRouteBaseName = useRouteBaseName();
const loadingStore = useLoadingStore();
const localePath = useLocalePath();
const route = useRoute();
const routeStore = useRouteStore();
const runtimeConfig = useRuntimeConfig();
const searchService = useSearchService();
const searchStore = useSearchStore();
const seoStore = useSeoStore();
const viewport = useViewport();
const { $gettext, $pgettext } = useGettext();
const { fetchError, fetchFailed, fetchStatus } = useFetchError();
const { getDescription } = useCampsiteDetailDescription({ campsite: () => campsiteStore.campsite });
const { getContextForCPDP } = useECommerceContext();
const { requestAds } = useAds();
const { trackAdClick, setCustomUrl, trackStructEvent, trackProductView } = useTracking();
const { scrollToRef, sectionRefs } = useCampsiteDetail({
  campsite: () => campsiteStore.campsite,
});
const { locale } = useI18n();
const { openModal, closeModal } = useCiModal();
const { hasAds, isIntegrationProvider, isPartOfAvailabilityService, preEnterData, getPreEnterData } = useCampsiteAdsAs({
  campsite: () => campsiteStore.campsite,
});

/********************
 * INITIALIZATION    *
 ********************/
if ((import.meta.client && !campsiteStore.campsite) || campsiteStore.campsite?.slug !== route.params.identifier) {
  await useAsyncData(route.fullPath, getPreEnterData);
}
const { pending, error, status } = await useLazyAsyncData(`cpdp-${route.params.identifier}`, async () => {
  const campsite = campsiteStore.campsite || null;

  let campsiteResponse = toRaw(campsite || null);
  if (!campsite || campsite.slug !== route.params.identifier) {
    try {
      campsiteResponse = await campsiteStore.getCampsite({ slug: route.params.identifier, lang: locale.value });
      if (!campsiteResponse) {
        return;
      }
    } catch (error) {
      fetchError(error);
      return error;
    }
  }
  if (!campsiteResponse) {
    return;
  }

  const imgCount
    = (campsiteResponse.media_count?.pictures.campsite || 0) + (campsiteResponse.media_count?.pictures.visitor || 0);
  seoStore.setDynamicMetaParam({
    campsite_federalstate: campsiteResponse.meta?.federal_state.name,
    campsite_imagecount: isNaN(imgCount) ? 0 : imgCount,
    campsite_reviewcount: campsiteResponse.rating_guest.rating_count || 0,
    campsite_city: campsiteResponse.meta?.city_obj?.name,
    campsite_country: campsiteResponse.meta.country?.name,
    campsite_name: campsiteResponse.meta?.name,
    campsite_rating: campsiteResponse.rating_campsite.rating_avg_overall || 0,
    custom_description: getDescription(),
  });

  const location = {
    country: campsiteResponse.meta.country,
    federal_state: campsiteResponse.meta.federal_state,
  };

  if (campsiteResponse.regions?.length) {
    location.region = campsiteResponse.regions[0];
  }

  searchStore.location = location;

  return {
    campsiteResponse,
  };
});

if (error.value) {
  throw createError({ statusCode: error.value?.statusCode, message: error.value.statusMessage });
}

/********************
 * HOOKS             *
 ********************/
onMounted(() => {
  if (appStore.isInitialRequest) {
    /*
      #######################################
      ### t = tracking mode               ###
      #######################################
      TRACKING_MODE_NORMAL = '0'
      TRACKING_MODE_ONLY_TRACKING = '1'
      TRACKING_MODE_NO_TRACKING = '2'
      */
    searchService.getCampsite(route.params.identifier, locale.value, { client: 'web', t: '1' });
    appStore.isInitialRequest = false;
  }

  getAvailabilitiesOrAccommodations();
  // add watcher for ref scrolling
  watch(() => pending.value, checkHashForScrolling, { immediate: false });
  setTimeout(() => {
    checkHashForScrolling(true);
  }, 100);
});

onBeforeRouteUpdate(() => {
  closeModal('CiMapModal');
});

onBeforeUnmount(() => {
  clearNuxtData(`cpdp-${route.params.identifier}`);
});

/********************
 * REFS & VARS       *
 ********************/
const formattedPrice = computed(() => {
  if (!campsiteStore.campsite || !campsiteStore.campsite.price) {
    return null;
  }
  const price = campsiteStore.campsite.price?.price_off_season || campsiteStore.campsite.price?.price1;
  if (!price) {
    return null;
  }
  return `${price.toFixed(2)} EUR`;
});

const jsonLd = computed(() => {
  if (campsiteStore.campsite === null) {
    return null;
  }

  let imagePath = campsiteStore.campsite.media?.images?.length
    ? fixImageUrl(backendStore.url, campsiteStore.campsite.media?.images[0].url)
    : 0;

  if (!imagePath) {
    imagePath = `${runtimeConfig.public.baseUrl}/img/campsite-fallback.png`;
  }

  const metadata = {
    '@context': 'http://schema.org/',
    '@type': 'Campground',
    '@id': '#campsite',
    'name': campsiteStore.campsite.meta?.name,
    'image': imagePath,
    'priceRange': formattedPrice.value,
    'address': {
      '@type': 'PostalAddress',
      'addressCountry': campsiteStore.campsite.meta?.country.name,
      'addressLocality': campsiteStore.campsite.meta?.name,
      'addressRegion': campsiteStore.campsite.meta?.federal_state?.name,
      'postalCode': campsiteStore.campsite.meta?.zip,
      'streetAddress': `${campsiteStore.campsite.meta?.street} ${campsiteStore.campsite.meta?.street_no}`,
    },
  };

  const reviewCount = campsiteStore.campsite?.rating_guest?.rating_count;
  if (reviewCount > 0) {
    metadata.aggregateRating = {
      '@type': 'AggregateRating',
      reviewCount,
      'ratingValue': campsiteStore.campsite?.rating_campsite?.rating_avg_overall,
    };
  }
  return metadata;
});

if (jsonLd.value) {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(jsonLd.value),
      },
    ],
  });
}

const backToSearchLink = computed(() => {
  return routeStore.lastSearchRoute?.fullPath;
});

const backToSearchLinkText = computed(() => {
  if (routeStore.lastSearchRoute && getRouteBaseName(routeStore.lastSearchRoute) === RBN_SEARCH_MAP) {
    return $gettext('Back to map');
  }
  return $gettext('Back to search');
});

const subNavigationAnchors = computed<{ anchor: string; label: string; ref: HTMLElement | null }[]>(() => {
  return [
    ...(hasAvailabilitySection.value
      ? [
          {
            anchor: 'availabilities',
            label: i18nAvailabilities.value,
            ref: sectionRefs.availabilities,
          },
        ]
      : []),
    { anchor: 'rating', label: $gettext('Ratings'), ref: sectionRefs.rating },
    ...(campsiteStore.campsite?.awards?.length
      ? [
          {
            anchor: 'awards',
            label: $gettext('Awards'),
            ref: sectionRefs.awards,
          },
        ]
      : []),
    { anchor: 'about', label: $gettext('Description'), ref: sectionRefs.about },
    { anchor: 'properties', label: $gettext('Properties'), ref: sectionRefs.properties },
    { anchor: 'location', label: $gettext('Location'), ref: sectionRefs.location },
    { anchor: 'contact', label: $gettext('Contact'), ref: sectionRefs.contact },
  ];
});

const breadcrumb = computed(() => {
  const breadcrumb = [];

  if (!campsiteStore.campsite || !campsiteStore.campsite.meta) {
    return breadcrumb;
  }

  if (campsiteStore.campsite.meta?.country) {
    breadcrumb.push({
      name: campsiteStore.campsite.meta.country.name,
      class: 'breadcrumb-item--chevron',
      to: localePath({
        name: RBN_COUNTRY_SEARCH,
        params: {
          countrySlug: campsiteStore.campsite.meta.country.slugs?.[locale.value],
        },
      }),
    });
  }

  if (campsiteStore.campsite.meta.federal_state) {
    breadcrumb.push({
      name: campsiteStore.campsite.meta.federal_state.name,
      class: 'breadcrumb-item--chevron',
      to: localePath({
        name: RBN_FEDERAL_STATE_SEARCH,
        params: {
          countrySlug: campsiteStore.campsite.meta.country.slugs?.[locale.value],
          federalStateSlug: campsiteStore.campsite.meta.federal_state.slugs?.[locale.value],
        },
      }),
    });
  }

  if (campsiteStore.campsite.meta.city_obj?.name) {
    breadcrumb.push({
      name: campsiteStore.campsite.meta.city_obj.name,
      class: 'breadcrumb-item--chevron',
      to: localePath({
        name: RBN_CITY_SEARCH,
        params: {
          citySlug: campsiteStore.campsite.meta.city_obj.slugs?.[locale.value],
        },
      }),
    });
  }

  breadcrumb.push({
    name: campsiteStore.campsite.meta.name,
    class: 'breadcrumb-item--chevron',
    hidden: true, // element is visually hidden but appears in the json+ld for the breadcrumb
  });

  // remove first chevron class
  breadcrumb[0].class = breadcrumb?.[0].class.replace(/breadcrumb-item--chevron/g, '') || '';

  return breadcrumb;
});

const latitude = computed(() => {
  return (campsiteStore.campsite?.meta?.geo_position?.coordinates?.[1] || '').toString();
});

const longitude = computed(() => {
  return (campsiteStore.campsite?.meta?.geo_position?.coordinates?.[0] || '').toString();
});

const campsiteMapData = computed(() => {
  return {
    id: campsiteStore.campsite?.id,
    name: campsiteStore.campsite?.meta?.name,
    rating_avg_overall: campsiteStore.campsite?.rating_campsite?.rating_avg_overall,
    rating_count: campsiteStore.campsite?.rating_guest?.rating_count,
    showEffect: true,
    slug: campsiteStore.campsite?.slug,
  };
});

const ratingOverview = computed(() => {
  if (!campsiteStore.campsite) {
    return [];
  }

  return [
    {
      key: 'rating_avg_calmness',
      translation: $gettext('Average calmness'),
    },
    {
      key: 'rating_avg_catering',
      translation: $gettext('Average catering'),
    },
    {
      key: 'rating_avg_cleanliness_overall',
      translation: $gettext('Average cleanliness overall'),
    },
    {
      key: 'rating_avg_cleanliness_sanitary',
      translation: $gettext('Average cleanliness sanitary'),
    },
    {
      key: 'rating_avg_condition_rentable_accomodations',
      translation: $gettext('Average condition rentable accomondation'),
    },
    {
      key: 'rating_avg_friendliness',
      translation: $gettext('Average friendliness'),
    },
    {
      key: 'rating_avg_infrastructure',
      translation: $gettext('Average infrastructure'),
    },
    {
      key: 'rating_avg_leisure',
      translation: $gettext('Average leisure'),
    },
    {
      key: 'rating_avg_location',
      translation: $gettext('Average location'),
    },
    {
      key: 'rating_avg_price_quality_ratio',
      translation: $gettext('Average price quality ratio'),
    },
    {
      key: 'rating_avg_sanitary',
      translation: $gettext('Average sanitary'),
    },
  ];
});

const guestsRating = computed(() => {
  const keys = Object.keys(campsiteStore.campsite?.rating_guest);
  const indexOfRatingCount = keys.indexOf('rating_count');
  keys.splice(indexOfRatingCount, 1);
  const ratingGuest = [];

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    ratingGuest.push({
      title: $gettext(translationFixtures[key]),
      value: campsiteStore.campsite?.rating_guest[key],
    });
  }
  ratingGuest.sort((a, b) => b.value - a.value);
  return ratingGuest;
});

const i18nPromoHeadline = computed(() => {
  return $gettext('You might also be interested in these campsites');
});

function onClickShowMap() {
  const CiMapModal = defineAsyncComponent({
    loader: () => import('@/components/map/CiMapModal/CiMapModal.vue'),
  });

  openModal(
    {
      component: CiMapModal,
      attrs: {
        campsite: campsiteMapData.value,
        latitude: latitude.value,
        longitude: longitude.value,
        title: campsiteStore.campsite?.meta?.name,
        name: 'CiMapModal',
        onClose: () => {
          closeModal('CiMapModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[1140px] w-full h-[80%]',
    },
  );
}

function onPriceTagClicked() {
  if (isIntegrationProvider.value) {
    scrollToRef(null, 'availabilities');
    return;
  }

  if (!isIntegrationProvider.value) {
    if (campsiteStore.campsite?.meta?.inquiries_allowed) {
      navigateTo(
        localePath({
          name: 'campsite-identifier-inquire',
          params: route.params,
          query: route.query,
        }),
      );
    } else {
      scrollToRef(null, 'about');
    }
  }
}

/********************
 * EQUIPMENT         *
 ********************/
const equipment = computed(() => {
  if (!campsiteStore.campsite) {
    return [];
  }

  const equipmentMapping = [
    {
      title: $gettext('Equipment'),
      data: [
        {
          path: 'equipment',
          key: 'central_bonfire_area',
          name: translationFixtures.central_bonfire_area,
        },
        {
          path: 'general',
          key: 'charcoal_grill_allowed',
          name: translationFixtures.charcoal_grill_allowed,
        },
        {
          path: 'count',
          key: 'count_rentable_bathrooms',
          name: translationFixtures.count_rentable_bathrooms,
        },
        {
          path: 'count',
          key: 'count_washing_cabins',
          name: translationFixtures.count_washing_cabins,
        },
        {
          path: 'sanitary',
          key: 'drying_room',
          name: translationFixtures.drying_room,
        },
        {
          path: 'equipment',
          key: 'firewood',
          name: translationFixtures.firewood,
        },
        {
          path: 'care',
          key: 'gas_bottle_exchange',
          name: translationFixtures.gas_bottle_exchange,
        },
        {
          path: 'equipment',
          key: 'internet_terminal',
          name: translationFixtures.internet_terminal,
        },
        {
          path: 'sanitary',
          key: 'laundry_driers',
          name: translationFixtures.laundry_driers,
        },
        {
          path: 'sanitary',
          key: 'lockers',
          name: translationFixtures.lockers,
        },
        {
          path: 'equipment',
          key: 'lounge',
          name: translationFixtures.lounge,
        },
        {
          path: 'equipment',
          key: 'tables_and_benches',
          name: translationFixtures.tables_and_benches,
        },
        {
          path: 'care',
          key: 'ver_entsorgungs_wohnmobile',
          name: translationFixtures.ver_entsorgungs_wohnmobile,
        },
        {
          path: 'sanitary',
          key: 'washingmachines',
          name: translationFixtures.washingmachines,
        },
        {
          path: 'equipment',
          key: 'wifi',
          name: translationFixtures.wifi,
        },
      ],
    },
    {
      title: $gettext('At the parking place'),
      data: [
        {
          key: 'bus_bar',
          path: 'equipment',
          name: translationFixtures.bus_bar,
        },
        {
          key: 'campfire_at_pitch_allowed',
          path: 'general',
          name: translationFixtures.campfire_at_pitch_allowed,
        },
        {
          key: 'car_allowed_at_pitch',
          path: 'general',
          name: translationFixtures.car_allowed_at_pitch,
        },
        {
          key: 'car_free',
          path: 'general',
          name: translationFixtures.car_free,
        },
        {
          key: 'gas_connection_at_pitch',
          path: 'equipment',
          name: translationFixtures.gas_connection_at_pitch,
        },
        {
          key: 'sewage_connection_at_pitch',
          path: 'equipment',
          name: translationFixtures.sewage_connection_at_pitch,
        },
        {
          key: 'shadow',
          path: 'descriptive',
          name: translationFixtures.shadow,
        },
        {
          key: 'spaceyouthgroup',
          path: 'general',
          name: translationFixtures.spaceyouthgroup,
        },
        {
          key: 'tv_connection_at_pitch',
          path: 'equipment',
          name: translationFixtures.tv_connection_at_pitch,
        },
        {
          key: 'water_connection_at_pitch',
          path: 'equipment',
          name: translationFixtures.water_connection_at_pitch,
        },
      ],
    },
    {
      title: $gettext('Pets'),
      data: [
        {
          key: 'dog_bathing',
          path: 'care',
          name: translationFixtures.dog_bathing,
        },
        {
          key: 'dog_meadow',
          path: 'care',
          name: translationFixtures.dog_meadow,
        },
        {
          key: 'dog_showers',
          path: 'care',
          name: translationFixtures.dog_showers,
        },
        {
          key: 'dogs_allowed_in_rented_accomodation',
          path: 'general',
          name: translationFixtures.dogs_allowed_in_rented_accomodation,
        },
        {
          key: 'dogs_allowed_off_season',
          path: 'general',
          name: translationFixtures.dogs_allowed_off_season,
        },
        {
          key: 'dogs_allowed_season',
          path: 'general',
          name: translationFixtures.dogs_allowed_season,
        },
      ],
    },
    {
      title: $gettext('Family & Children'),
      data: [
        { path: 'sanitary', key: 'baby_change', name: translationFixtures.baby_change },
        { path: 'leisure', key: 'childrens_playground', name: translationFixtures.childrens_playground },
        {
          path: 'leisure',
          key: 'indoor_gaming_possibilities',
          name: translationFixtures.indoor_gaming_possibilities,
        },
        { path: 'leisure', key: 'petting_zoo', name: translationFixtures.petting_zoo },
        { path: 'leisure', key: 'kids_animation', name: translationFixtures.kids_animation },
      ],
    },
    {
      title: $gettext('Swimming & Wellness'),
      data: [
        { path: 'leisure', key: 'swimming_nature', name: translationFixtures.swimming_nature },
        { path: 'leisure', key: 'hot_springs', name: translationFixtures.hot_springs },
        { path: 'leisure', key: 'sauna', name: translationFixtures.sauna },
        { path: 'leisure', key: 'swimming_pool_outdoor', name: translationFixtures.swimming_pool_outdoor },
        { path: 'leisure', key: 'sandbeach', name: translationFixtures.sandbeach },
        { path: 'leisure', key: 'swimming_pool_indoor', name: translationFixtures.swimming_pool_indoor },
        { path: 'leisure', key: 'water_slide', name: translationFixtures.water_slide },
        { path: 'leisure', key: 'nudist_beach', name: translationFixtures.nudist_beach },
      ],
    },
    {
      title: $gettext('Leisure opportunities'),
      data: [
        {
          key: 'bicycle_rent',
          path: 'leisure',
          name: translationFixtures.bicycle_rent,
        },
        {
          key: 'boat_hire',
          path: 'leisure',
          name: translationFixtures.boat_hire,
        },
        {
          key: 'cross_country_skiing',
          path: 'leisure',
          name: translationFixtures.cross_country_skiing,
        },
        {
          key: 'diving_station',
          path: 'leisure',
          name: translationFixtures.diving_station,
        },
        {
          key: 'fishing',
          path: 'leisure',
          name: translationFixtures.fishing,
        },
        {
          key: 'golf',
          path: 'leisure',
          name: translationFixtures.golf,
        },
        {
          key: 'horseback_riding',
          path: 'leisure',
          name: translationFixtures.horseback_riding,
        },
        {
          key: 'mini_golf',
          path: 'leisure',
          name: translationFixtures.mini_golf,
        },
        {
          key: 'skilift',
          path: 'leisure',
          name: translationFixtures.skilift,
        },
        {
          key: 'slipway',
          path: 'leisure',
          name: translationFixtures.slipway,
        },
        {
          key: 'table_tennis',
          path: 'leisure',
          name: translationFixtures.table_tennis,
        },
        {
          key: 'tennis',
          path: 'leisure',
          name: translationFixtures.tennis,
        },
        {
          key: 'volleyball',
          path: 'leisure',
          name: translationFixtures.volleyball,
        },
        {
          key: 'windsurfing',
          path: 'leisure',
          name: translationFixtures.windsurfing,
        },
      ],
    },
    {
      title: $gettext('Fare & Catering'),
      data: [
        {
          path: 'care',
          key: 'restaurant',
          name: translationFixtures.restaurant,
          openingHours: {
            start: { path: 'meta', key: 'opening_hours_restaurant_begin' },
            end: { path: 'meta', key: 'opening_hours_restaurant_end' },
          },
        },
        {
          path: 'care',
          key: 'snack',
          name: translationFixtures.snack,
          openingHours: {
            start: { path: 'meta', key: 'opening_hours_snack_begin' },
            end: { path: 'meta', key: 'opening_hours_snack_end' },
          },
        },
        {
          path: 'care',
          key: 'food_supply',
          name: translationFixtures.food_supply,
          openingHours: {
            start: { path: 'meta', key: 'opening_hours_foodsupply_begin' },
            end: { path: 'meta', key: 'opening_hours_foodsupply_end' },
          },
        },
        {
          path: 'descriptive',
          key: 'bread_at_campsite',
          name: translationFixtures.bread_at_campsite,
        },
        { path: 'equipment', key: 'cooking_facilities', name: translationFixtures.cooking_facilities },
      ],
    },
    {
      title: $gettext('Accessibility'),
      data: [
        {
          key: 'sanitarian_handicapped',
          path: 'sanitary',
          name: translationFixtures.sanitarian_handicapped,
        },
        { path: 'general', key: 'wheelchair_ramps', name: translationFixtures.wheelchair_ramps },
        {
          key: 'handicapped_accessible_water_entrance',
          path: 'equipment',
          name: translationFixtures.handicapped_accessible_water_entrance,
        },
        {
          key: 'most_ways_paved',
          path: 'descriptive',
          name: translationFixtures.most_ways_paved,
        },
      ],
    },
    {
      title: $gettext('Rental accommodations'),
      data: [
        { path: 'rental', key: 'apartments', name: translationFixtures.apartments },
        { path: 'rental', key: 'caravan', name: translationFixtures.caravan },
        { path: 'rental', key: 'mobile_bungalows', name: translationFixtures.mobile_bungalows },
        { path: 'rental', key: 'rent_cabins', name: translationFixtures.rent_cabins },
        { path: 'rental', key: 'tents', name: translationFixtures.tents },
      ],
    },
    {
      title: $gettext('Vacation type'),
      data: [
        {
          key: 'nudism',
          path: 'general',
          name: translationFixtures.nudism,
        },
        {
          key: 'winter_camping',
          path: 'general',
          name: translationFixtures.winter_camping,
        },
        {
          key: 'durable_camping',
          path: 'general',
          name: translationFixtures.durable_camping,
        },
        {
          key: 'mini_camping',
          path: 'general',
          name: translationFixtures.mini_camping,
        },
      ],
    },
  ];

  return equipmentMapping.map((category) => {
    return {
      ...category,
      data: Object.assign(...category.data.map(obj => getEquipmentItem(obj))),
    };
  });
});

function getEquipmentItem({ path, key, name, openingHours }) {
  if (!path || !key) {
    return {};
  }

  const translated
    = typeof name === 'object' && name !== null ? $pgettext(name?.ctx, name?.key) : $gettext(name) || key;

  const value = RamdaPath([...path.split('.'), key], campsiteStore.campsite);

  // add opening hours
  if (openingHours) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const start = RamdaPath([...openingHours.start?.path?.split('.'), openingHours.start?.key], campsiteStore.campsite);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const end = RamdaPath([...openingHours.end?.path?.split('.'), openingHours.end?.key], campsiteStore.campsite);

    const openingTimes = {};

    if (start) {
      openingTimes.start = dayjs(start).format('DD.MM.');
    }

    if (end) {
      openingTimes.end = dayjs(end).format('DD.MM.');
    }

    if (Object.entries(openingTimes).length) {
      return { [translated]: { ...value, openingTimes } };
    }
  }

  return { [translated]: value };
}

/********************
 * CAMPSITE          *
 ********************/
function onCampsiteChange() {
  const slug = route.params.identifier;
  campsiteStore.getRecommendations({ slug, lang: locale.value });

  const { acco_type, adults, children, flex, from, until, discounts } = { ...route.query };
  searchStore.setSearchParams({
    ...toRaw(searchStore.searchParams),
    acco_type: acco_type || null,
    adults: adults ? parseInt(adults) : 2,
    children: children || null,
    discounts: discounts || null,
    flex: flex ? parseInt(flex) : 3,
    from: from || null,
    until: until || null,
  });
}

watch(() => route.params.identifier, onCampsiteChange, { immediate: true });

watch(() => status.value, (status) => {
  if (status === 'success') {
    nextTick(() => {
      // refresh ad slots
      requestAds();
    });

    trackProductView({
      id: campsiteStore.campsite.camping_id.toString(),
      name: campsiteStore.campsite.meta?.name,
      brand: 'camping.info',
      category: 'campsite',
      price: Math.round((campsiteStore.campsite.price?.price_off_season || campsiteStore.campsite.price?.price1 || 0) * 100) / 100,
      currency: 'EUR',
      context: getContextForCPDP(campsiteStore.campsite),
    });
  }
}, { immediate: true });

/********************
 * AVAILABILITIES    *
 * ACCOMMODATIONS    *
 ********************/
const searchMobileUse = computed(() => {
  return viewport.isLessThan('lg');
});

const hasAvailabilitySection = computed(() => {
  if (!import.meta.client) {
    return false;
  }

  return isPartOfAvailabilityService.value;
});

const filteredAvailabilities = computed(() => {
  const from = route.query.from;
  const until = route.query.until;
  if (campsiteStore.availabilities) {
    const tempArray = [...campsiteStore.availabilities].sort((a, b) => {
      if (a.start_date !== b.start_date || a.end_date !== b.end_date) {
        if (a.start_date === from && a.end_date === until) {
          return -1;
        } else if (b.start_date === from && b.end_date === until) {
          return 1;
        }
      }

      const ta = a.acco_type;
      const tb = b.acco_type;

      if (ta === tb) {
        return 0;
      }
      if (ta === 'pitch') {
        return -1;
      }
      return 1;
    });
    return tempArray;
  }
  return [];
});

const i18nAvailabilities = computed(() => {
  return $pgettext('Availabilities section headline', 'Availabilities');
});

const i18nNoFreeAccommodation = computed(() => {
  return $gettext(
    'Unfortunately, no free accommodation can be found for these travel dates. You can change your travel dates or send an inquiry to the campsite.',
  );
});

const i18nNoFreeAccommodationNoInquiry = computed(() => {
  return $gettext(
    'Unfortunately, no available accommodation can be found for these travel dates. You can change your travel dates.',
  );
});

function hasAvailabilityOfType(accoType) {
  if (!campsiteStore.availabilities || !campsiteStore.availabilities.length) {
    return false;
  }
  return campsiteStore.availabilities.filter(acco => acco.acco_type === accoType).length > 0;
}

function onClickShowAccommodationDetails({ campsite, id, snowplow, availabilities }) {
  if (!campsiteStore.accommodationDetails || !campsiteStore.accommodationDetails[id]) {
    campsiteStore.getAccommodationDetails({ slug: campsiteStore.campsite.slug, id, lang: locale.value });
  }

  const CiCampsiteDetailAccommodationModal = defineAsyncComponent({
    loader: () =>
      import('@/components/campsitedetail/CiCampsiteDetailAccommodationModal/CiCampsiteDetailAccommodationModal.vue'),
  });

  openModal(
    {
      component: CiCampsiteDetailAccommodationModal,
      attrs: {
        name: 'CiCampsiteDetailAccommodationModal',
        availabilities,
        campsite,
        id,
        spContextAccommodationTile: snowplow,
        onClose: () => {
          closeModal('CiCampsiteDetailAccommodationModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'w-full max-w-[800px] p-0 max-h-full',
    },
  );

  trackAdClick({
    targetUrl: 'modal',
    bannerId: snowplow.bannerId,
    zoneId: 'show-details',
    impressionId: snowplow.impressionId,
    context: snowplow.context,
  });
}

function hasAccommodationOfType(accoType) {
  if (!campsiteStore.accommodations || !campsiteStore.accommodations.length) {
    return false;
  }
  return campsiteStore.accommodations.filter(acco => acco.acco_type === accoType).length > 0;
}

function onCheckAvailabilities() {
  scrollToRef(null, 'availabilities');

  if (searchStore.searchOpenType !== 'date') {
    sectionRefs.search?.openOpenType('date');
  }
}

function getAvailabilitiesOrAccommodations(newQuery?: any, oldQuery?: any) {
  const slug = route.params.identifier;
  const toQuery = { ...route.query };

  if (toQuery.from && !toQuery.until) {
    toQuery.until = toQuery.from;
  }

  if (newQuery !== undefined) {
    if (!equals(newQuery, oldQuery)) {
      gtagReportConversion('piDICPLpjc8DEKKErIID');
    }
  }

  if (toQuery.from) {
    campsiteStore
      .getLiveAvailabilities({ slug, lang: locale.value, query: toQuery })
      .then((result) => {
        if (!result || !result.length) {
          campsiteStore.getAccommodations({ slug, lang: locale.value, query: toQuery }).catch(() => {});
        }
        nextTick(() => {
          checkAndTrackEmptyAvailability();
        });
      })
      .catch(() => {
        appStore.clickContext = null;
      });
  } else {
    campsiteStore.getAccommodations({ slug, lang: locale.value, query: toQuery }).catch(() => {});
  }
}

function checkAndTrackEmptyAvailability() {
  if (appStore.clickContext !== 'availability') {
    return;
  }
  if (loadingStore.isLoading('getLiveAvailabilities')) {
    setTimeout(checkAndTrackEmptyAvailability, 500);
    return;
  }
  if (!campsiteStore.availabilities || campsiteStore.availabilities.length === 0) {
    const url = getCurrentURL(runtimeConfig.public.baseUrl, route);
    setCustomUrl(url);
    trackStructEvent({
      category: 'availability-service',
      action: 'load-availabilities',
      label: 'availabilities',
      value: 0.0,
    });
  }
  appStore.clickContext = null;
}

watch(() => route.query, getAvailabilitiesOrAccommodations, { immediate: false });

/********************
 * SCROLLING         *
 ********************/
function checkHashForScrolling(newValue: boolean) {
  if (newValue && route.hash) {
    if (route.hash === '#availabilities') {
      scrollToRef(null, 'availabilities');
    } else {
      const watchOn = route.query.from ? 'getLiveAvailabilities' : 'getAccommodations';
      let interval: any = null;
      const section = route.hash.replace('#', '');
      if (loadingStore.isLoading(watchOn)) {
        interval = setInterval(() => {
          if (!loadingStore.isLoading(watchOn)) {
            clearInterval(interval);
            scrollToRef(null, section);
          }
        }, 100);
      } else {
        scrollToRef(null, section);
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 5000);
    }
  }
}

/********************
 * PAGINATION        *
 ********************/
function onPaginationChange() {
  if (!sectionRefs.availabilities) {
    return;
  }

  nextTick(() => {
    sectionRefs.availabilities?.scrollIntoView({ block: 'start' });
  });
}

watch(() => route.query.offset, onPaginationChange, { immediate: false });
</script>

<template>
  <div
    v-if="!fetchFailed"
    class="relative"
  >
    <div
      v-if="!pending && campsiteStore.campsite"
      class="campsite-detail relative before:absolute before:left-0 before:top-0 before:block before:h-[10.5rem] before:w-full before:bg-gray-10 before:content-[''] md:before:h-[29.3rem] lg:before:h-[22.5rem]"
      :class="{
        'campsite-detail--ad md:pt-6 lg:pt-12': hasAds,
        'md:pt-6 lg:pt-12': !backToSearchLink && !hasAds,
      }"
    >
      <div
        v-if="viewport.isGreaterOrEquals('xl') && hasAds"
        class="skyscraper absolute size-full pb-12"
      >
        <div class="container h-full">
          <div class="flex h-full">
            <CiAdSlot
              id="camping_sidebar_left"
              class="ad--skyscraper ad--skyscraper-left sticky top-0"
              style="top: 50px; z-index: 31"
            />
            <CiAdSlot
              id="camping_sidebar_1"
              class="ad--skyscraper ad--skyscraper-right sticky top-0"
              style="top: 50px; z-index: 31"
            />
            <!-- won't be rendered -->
            <!-- <ci-ad-slot id="cpdp_wallpaper_pixel"></ci-ad-slot> -->
          </div>
        </div>
      </div>

      <div
        v-if="hasAds"
        class="container-lg mb-4 pt-0"
      >
        <CiAdSlot
          v-if="viewport.isLessThan('lg')"
          id="camping_header"
          class="ad--header"
          style="max-height: 100px !important"
        />
        <CiAdSlot
          v-if="viewport.isGreaterOrEquals('lg')"
          id="camping_header"
          class="ad--header"
        />
      </div>

      <!-- back navigation -->
      <div
        v-if="backToSearchLink"
        class="container py-2"
      >
        <div class="row">
          <div class="col-12">
            <CiNavigationToggle
              type="black"
              :href="backToSearchLink"
            >
              <span class="ml-4 text-dark">{{ backToSearchLinkText }}</span>
            </CiNavigationToggle>
          </div>
        </div>
      </div>

      <!-- images -->
      <CiCampsiteDetailHero
        :campsite="campsiteStore.campsite"
        :campsite-map-data="campsiteMapData"
        @on-map-clicked="onClickShowMap"
      />

      <!-- price tag / breadcrumb -->
      <div class="container relative mt-6">
        <div class="absolute right-[30px] top-0 -mt-6 mr-0 -translate-y-1/2 cursor-pointer md:mr-4">
          <CiPriceTag
            :price-main-season="campsiteStore.campsite.price?.price1"
            :price-off-season="campsiteStore.campsite.price?.price_off_season"
            @click="onPriceTagClicked"
          />
        </div>
        <CiBreadcrumb
          v-if="breadcrumb && breadcrumb.length"
          :items="breadcrumb"
          :show-home="false"
          class="mb-2 pt-4"
          scrollable-classes="md:justify-center"
        />
      </div>

      <!-- base infos -->
      <CiCampsiteSectionBase
        class="pb-12"
        :campsite="campsiteStore.campsite"
        :pre-enter-data="preEnterData"
        :is-content-loading="loadingStore.isLoading('getContent')"
        :scroll-to-ref="scrollToRef"
        :is-integration-provider="isIntegrationProvider"
      />

      <div
        v-if="hasAds"
        class="container"
      >
        <div class="row">
          <div class="col-12">
            <CiAdSlot
              id="camping_content_2"
              class="ad--h-600-480-570"
            />
          </div>
        </div>
      </div>

      <!-- https://github.com/smastrom/vue-use-active-scroll?tab=readme-ov-file -->
      <CiStickyNavigation
        :ref="el => (sectionRefs['subnav'] = el as InstanceType<typeof CiStickyNavigation>)"
        class="mt-12"
        :items="subNavigationAnchors"
        @item-clicked="scrollToRef"
      />

      <!-- availabilities -->
      <client-only>
        <section
          v-if="hasAvailabilitySection"
          id="availabilities"
          :ref="el => (sectionRefs['availabilities'] = el as HTMLElement)"
          class="section section--availabilities lg:before:h-[calc(100%-15rem)]lg:before:top-60 relative py-12 before:absolute before:left-0 before:top-48 before:w-full before:bg-gray-10 before:content-['']"
          :class="[
            !isIntegrationProvider ? 'before:top-[6.5rem] before:h-[calc(100%-6.5rem)]' : 'before:h-[calc(100%-12rem)]',
          ]"
        >
          <h2
            key="section-headline-availabilities"
            class="px-6 text-center text-black"
          >
            {{ i18nAvailabilities }}
          </h2>
          <div class="container mt-12 pt-6">
            <!-- integration -->
            <template v-if="isIntegrationProvider">
              <div class="row">
                <!-- search -->
                <div class="col-12 col-lg-4">
                  <div class="relative top-auto lg:mb-6">
                    <CiSearch
                      :ref="el => (sectionRefs['search'] = el as InstanceType<typeof CiSearch>)"
                      :is-mobile="searchMobileUse"
                      collapsible
                      is-detail-page
                    />
                  </div>
                </div>

                <!-- results -->
                <div class="col-12 col-lg-8 mt-5 lg:mt-0">
                  <!-- availabilities -->
                  <template v-if="route.query.from">
                    <div key="has-params">
                      <template v-if="loadingStore.isLoading('getLiveAvailabilities')">
                        <div>
                          <div class="availability-loading-info absolute bg-white text-center text-sm opacity-80">
                            <div class="m-auto size-[45px]">
                              <CiAnimatedLoadingIndicator />
                            </div>
                            <div class="mt-4 font-medium">
                              {{ $gettext("We'll find you the best offer.") }}
                            </div>
                            <p>
                              {{
                                $gettext('This may take some time as we request the info directly from the campsite.')
                              }}
                            </p>
                          </div>
                          <CiSkeletonCampsiteDetailAvailabilityTile
                            class="mb-6"
                            has-availabilities
                          />
                          <CiSkeletonCampsiteDetailAvailabilityTile
                            class="mb-6"
                            has-availabilities
                          />
                          <CiSkeletonCampsiteDetailAvailabilityTile
                            class="mb-6"
                            has-availabilities
                          />
                          <CiSkeletonCampsiteDetailAvailabilityTile has-availabilities />
                        </div>
                      </template>
                      <template v-else-if="campsiteStore.availabilities && campsiteStore.availabilities?.length > 0">
                        <div
                          v-if="hasAvailabilityOfType('acco') && hasAvailabilityOfType('pitch')"
                          class="mb-4"
                        >
                          {{
                            $ngettext(
                              'There is %{ count } accommodation/pitch available within your travel dates',
                              'There are %{ count } accommodations/pitches available within your travel dates',
                              campsiteStore.availabilities.length,
                              { count: campsiteStore.availabilities.length },
                            )
                          }}
                        </div>
                        <div
                          v-else-if="hasAvailabilityOfType('acco')"
                          class="mb-4"
                        >
                          {{
                            $ngettext(
                              'There is %{ count } accommodation available within your travel dates',
                              'There are %{ count } accommodations available within your travel dates',
                              campsiteStore.availabilities.length,
                              { count: campsiteStore.availabilities.length },
                            )
                          }}
                        </div>
                        <div
                          v-else
                          class="mb-4"
                        >
                          {{
                            $ngettext(
                              'There is %{ count } pitch available within your travel dates',
                              'There are %{ count } pitches available within your travel dates',
                              campsiteStore.availabilities.length,
                              { count: campsiteStore.availabilities.length },
                            )
                          }}
                        </div>
                        <template v-if="filteredAvailabilities.length">
                          <div
                            v-for="(availability, idx) in filteredAvailabilities.slice(
                              parseInt(route.query.offset as string) || 0,
                              (parseInt(route.query.offset as string) || 0) + campsiteStore.availabilitiesResultLimit,
                            )"
                            :key="`availability-${availability.accommodation_id}`"
                            class="mb-6"
                          >
                            <CiAccommodationTile
                              :campsite="campsiteStore.campsite"
                              :accommodation="availability"
                              :snowplow-context="{
                                bannerId: 'campsite_detail',
                                position: idx + 1,
                              }"
                              @show-details="onClickShowAccommodationDetails"
                            />
                          </div>
                          <CiPagination
                            v-if="campsiteStore.availabilitiesTotalPages > 1"
                            :offset="campsiteStore.availabilitiesResultLimit"
                            :current-page="campsiteStore.availabilitiesCurrentPage"
                            :max-visible-buttons="3"
                            :total-pages="campsiteStore.availabilitiesTotalPages"
                          />
                        </template>
                      </template>
                      <template v-else>
                        <CiInfoPanel
                          :condition="true"
                          type="info"
                        >
                          <div class="flex items-center py-4">
                            <CiAwesomeIcon
                              class="mx-6 fill-info"
                              :icon="faExclamationCircle"
                              ratio="1"
                            />
                            <div>
                              <p class="m-0">
                                {{
                                  campsiteStore.campsite.meta?.inquiries_allowed
                                    ? i18nNoFreeAccommodation
                                    : i18nNoFreeAccommodationNoInquiry
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                            v-if="campsiteStore.campsite.meta?.inquiries_allowed"
                            class="mb-4 flex justify-center"
                          >
                            <nuxt-link
                              :to="
                                localePath({
                                  name: 'campsite-identifier-inquire',
                                  params: route.params,
                                  query: route.query,
                                })
                              "
                              class="button button--primary"
                            ><span>{{ $gettext('Send inquiry') }}</span></nuxt-link>
                          </div>

                          <!-- closest campsites with availabilities -->
                          <NuxtLazyHydrate when-visible>
                            <CiClosestAvailableCampsites
                              layout="small"
                              :campsite-slug="route.params.identifier"
                              :from="route.query.from"
                              :until="route.query.until"
                              :adults="route.query.adults"
                              :children="route.query.children"
                              :acco-type="route.query.acco_type"
                              :use-container="false"
                            />
                          </NuxtLazyHydrate>
                        </CiInfoPanel>
                      </template>
                    </div>
                  </template>

                  <!-- accommodations -->
                  <template
                    v-if="!route.query.from || (campsiteStore.availabilities && !campsiteStore.availabilities.length)"
                  >
                    <template v-if="loadingStore.isLoading('getAccommodations')">
                      <CiSkeletonCampsiteDetailAvailabilityTile class="mb-6" />
                      <CiSkeletonCampsiteDetailAvailabilityTile class="mb-6" />
                      <CiSkeletonCampsiteDetailAvailabilityTile class="mb-6" />
                      <CiSkeletonCampsiteDetailAvailabilityTile />
                    </template>
                    <template v-else-if="campsiteStore.accommodations">
                      <div
                        v-if="hasAccommodationOfType('acco') && hasAccommodationOfType('pitch')"
                        class="mb-3"
                      >
                        {{ $gettext('All accommodations and pitches') }}
                      </div>
                      <div
                        v-else-if="hasAccommodationOfType('acco')"
                        class="mb-3"
                      >
                        {{ $gettext('All accommodations') }}
                      </div>
                      <div
                        v-else
                        class="mb-3"
                      >
                        {{ $gettext('All pitches') }}
                      </div>
                      <div
                        v-for="(accommodation, idx) in campsiteStore.accommodations.slice(
                          route.query.offset || 0,
                          (route.query.offset || 0) + campsiteStore.accommodationsResultLimit,
                        )"
                        :key="`acco-${accommodation.id}`"
                        class="mb-4"
                      >
                        <CiAccommodationTile
                          :campsite="campsiteStore.campsite"
                          :accommodation="accommodation"
                          :snowplow-context="{
                            bannerId: 'campsite_detail',
                            position: idx + 1,
                          }"
                          :check-availabilities-button="!route.query.from"
                          @check-availabilities="onCheckAvailabilities"
                          @show-details="onClickShowAccommodationDetails"
                        />
                      </div>
                      <CiPagination
                        v-if="campsiteStore.accommodationsTotalPages > 1"
                        :offset="campsiteStore.accommodationsResultLimit"
                        :current-page="campsiteStore.accommodationsCurrentPage"
                        :max-visible-buttons="3"
                        :total-pages="campsiteStore.accommodationsTotalPages"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </section>
      </client-only>

      <div class="campsite-detail__sections relative">
        <!-- rating & recommendations -->
        <section
          v-if="!loadingStore.isLoading('getCampsite')"
          id="rating"
          :ref="el => (sectionRefs['rating'] = el as HTMLElement)"
          class="py-12"
        >
          <!-- rating -->
          <CiCampsiteSectionRating
            :campsite="campsiteStore.campsite"
            :activities="campsiteStore.activities"
            :suggested-guests="guestsRating"
            :rating-overview="ratingOverview"
            :has-ads="hasAds"
          />

          <!-- recommendations top -->
          <client-only>
            <NuxtLazyHydrate when-visible>
              <div
                v-if="campsiteStore.recommendationsTop && campsiteStore.recommendationsTop.length > 0"
                class="overflow-x-hidden"
              >
                <CiPromoSlider
                  v-if="!loadingStore.isLoading('getRecommendations')"
                  :campsites="campsiteStore.recommendationsTop"
                >
                  <!-- headline -->
                  <template #headline>
                    <h2
                      class="m-0 mb-4 text-center"
                      v-html="i18nPromoHeadline"
                    />
                  </template>

                  <!-- slides -->
                  <template #slide="{ campsite, index }">
                    <CiPromoTile
                      :campsite="campsite"
                      :snowplow-context="{
                        bannerId: 'RecommendedCampsitesSlider',
                        campaignId: 'nearest',
                        zoneId: 'belowRating',
                        position: index + 1,
                      }"
                    />
                  </template>
                </CiPromoSlider>
              </div>
            </NuxtLazyHydrate>
          </client-only>
        </section>

        <!-- awards -->
        <client-only>
          <section
            v-if="!loadingStore.isLoading('getCampsite') && campsiteStore.campsite.awards.length > 0"
            id="awards"
            :ref="el => (sectionRefs['awards'] = el as HTMLElement)"
            class="py-12"
          >
            <CiCampsiteSectionAwardsLabels :awards="campsiteStore.campsite.awards" />
          </section>
        </client-only>

        <!-- about -->
        <section
          id="about"
          :ref="el => (sectionRefs['about'] = el as HTMLElement)"
          class="py-12"
        >
          <CiCampsiteSectionAbout
            v-once
            :has-ads="hasAds"
            :campsite="campsiteStore.campsite"
            :return-click="isIntegrationProvider"
            @button-clicked="scrollToRef($event, 'availabilities')"
          />
        </section>

        <!-- equipment -->
        <client-only>
          <section
            id="properties"
            :ref="el => (sectionRefs['properties'] = el as HTMLElement)"
            class="py-12"
          >
            <CiCampsiteSectionEquipment
              v-if="equipment.length > 0"
              v-once
              :equipment="equipment"
            />

            <div
              v-if="hasAds"
              class="container mt-6"
            >
              <div class="row">
                <div class="col-12">
                  <CiAdSlot
                    id="camping_content_5"
                    class="ad--h-250"
                  />
                </div>
              </div>
            </div>
          </section>
        </client-only>

        <!-- location -->
        <section
          id="location"
          :ref="el => (sectionRefs['location'] = el as HTMLElement)"
          class="py-12"
        >
          <CiCampsiteSectionLocation
            v-once
            :campsite="campsiteStore.campsite"
            :campsite-map-data="campsiteMapData"
            @map-clicked="onClickShowMap"
          />
        </section>

        <!-- contact -->
        <section
          id="contact"
          :ref="el => (sectionRefs['contact'] = el as HTMLElement)"
          class="py-12"
        >
          <CiCampsiteSectionContact
            v-once
            :campsite="campsiteStore.campsite"
            zone="bottom"
            :has-availability-section="hasAvailabilitySection"
            @check-availability="scrollToRef($event, 'availabilities')"
          />
        </section>

        <!-- faq -->
        <section
          v-if="locale === 'de' || locale === 'en' || locale === 'it' || locale === 'nl' || locale === 'fr'"
          id="faq"
          :ref="el => (sectionRefs['faq'] = el as HTMLElement)"
          class="py-12"
        >
          <CiCampsiteSectionFaq
            v-once
            :campsite="campsiteStore.campsite"
            :rating-translations="ratingOverview"
            :has-availability="isPartOfAvailabilityService"
            @scroll-to-ref="$event => scrollToRef(null, $event)"
          />
        </section>
      </div>

      <!-- recommendations bottom -->
      <client-only>
        <div
          v-if="campsiteStore.recommendationsBottom && campsiteStore.recommendationsBottom.length > 0"
          class="overflow-x-hidden"
        >
          <CiPromoSlider
            v-if="!loadingStore.isLoading('getRecommendations')"
            :campsites="campsiteStore.recommendationsBottom"
          >
            <!-- headline -->
            <template #headline>
              <h2
                class="m-0 mb-4 text-center"
                v-html="i18nPromoHeadline"
              />
            </template>

            <!-- slides -->
            <template #slide="{ campsite, index }">
              <CiPromoTile
                :campsite="campsite"
                :snowplow-context="{
                  bannerId: 'RecommendedCampsitesSlider',
                  campaignId: 'nearest',
                  zoneId: 'belowContact',
                  position: index + 1,
                }"
              />
            </template>
          </CiPromoSlider>
        </div>
      </client-only>
    </div>
    <CiSkeletonCampsiteDetail
      v-else
      :has-top-link="!!backToSearchLink"
      :has-ads="hasAds"
    />
  </div>
  <CiErrorView
    v-else
    :error="fetchStatus"
  />
</template>

<style lang="scss" scoped>
.campsite-detail {
  &--ad {
    &:before {
      height: 20rem;

      @include media-breakpoint-up(md) {
        height: 39.3rem;
      }
    }
  }

  // :deep(.chart-wrapper) {
  //   background-color: $white;
  //   border-radius: 50%;
  //   max-width: 100px;
  // }
}

.campsite-detail__sections > section {
  &:nth-child(odd) {
    @apply bg-white;
  }

  &:nth-child(even) {
    @apply bg-gray-10;
  }
}

.availability-loading-info {
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  margin: 25px 45px 25px 30px;
  padding: 25px;
  z-index: 1;

  @include media-breakpoint-up(md) {
    margin: 25px 115px 25px 100px;
  }
}
</style>
