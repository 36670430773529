<script setup lang="ts">
import CiSkeletonItem from '@/components/skeleton/CiSkeletonItem.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiSkeletonCampsiteDetailAvailabilityTileProps {
  hasAvailabilities?: boolean;
}
const props = withDefaults(defineProps<CiSkeletonCampsiteDetailAvailabilityTileProps>(), {
  hasAvailabilities: false,
});
</script>

<template>
  <div>
    <div
      class="relative block overflow-hidden border border-gray-20 bg-white md:h-[14.4375rem] lg:h-[12.6875rem] xl:h-[15.1875rem]"
      :class="[
        props.hasAvailabilities
          ? 'rounded-b-none rounded-t-lg border-b-0'
          : 'rounded-lg',
      ]"
    >
      <div class="row no-gutters h-full">
        <div class="col-12 col-md-4 relative">
          <CiSkeletonItem class="h-full pt-[75%]" />
        </div>

        <div class="col-12 col-md-8 h-full">
          <div class="ml-0 h-full p-2 md:ml-2">
            <div class="row no-gutters h-full">
              <div class="col-12 col-md-8">
                <div class="flex h-full flex-col">
                  <CiSkeletonItem class="mb-1 block h-5 w-[30%]" />
                  <CiSkeletonItem class="block h-[30px] w-[70%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasAvailabilities"
      class="h-[100px] w-full overflow-hidden rounded-lg rounded-t-none border border-t-0 border-gray-20 bg-white"
    />
  </div>
</template>

<style></style>
