<script setup lang="ts">
// utils
import dayjs from '@/utils/day';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiOpeningVisualizationProps {
  openingRanges: any;
  opening: any;
}
const props = withDefaults(defineProps<CiOpeningVisualizationProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const viewport = useViewport();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const tooltipConfig = computed(() => {
  return {
    placement: viewport.isLessThan('md') ? 'right' : 'top',
    modifiers: [],
  };
});

const months = computed(() => {
  return dayjs.monthsShort();
});

const today = computed(() => {
  return dayjs();
});

const todayStyles = computed(() => {
  const toLeft = (today.value.date() / today.value.daysInMonth()) * 100;

  if (viewport.isLessThan('md')) {
    return {
      top: `${toLeft}%`,
    };
  } else {
    return {
      left: `${toLeft}%`,
    };
  }
});

const openStatusStyles = computed(() => {
  return {};
});

const monthStartStyles = computed(() => {
  const styles: any = {};
  if (viewport.isLessThan('md')) {
    styles.top = 'auto';
    styles.bottom = 0;
    styles.borderTopRightRadius = '5px';
    styles.borderTopLeftRadius = '5px';
  } else {
    styles.right = 0;
    styles.left = 'auto';
    styles.borderTopLeftRadius = '5px';
    styles.borderBottomLeftRadius = '5px';
    styles.justifyContent = 'start';
  }
  return styles;
});

const monthEndStyles = computed(() => {
  const styles: any = {};
  if (viewport.isLessThan('md')) {
    styles.bottom = 'auto';
    styles.top = 0;
    styles.borderBottomRightRadius = '5px';
    styles.borderBottomLeftRadius = '5px';
    styles.justifyContent = 'end !important';
  } else {
    styles.right = 'auto';
    styles.left = 0;
    styles.borderTopRightRadius = '5px';
    styles.borderBottomRightRadius = '5px';
    styles.justifyContent = 'end !important';
  }
  return styles;
});

const isCurrentlyOpen = computed(() => {
  if (!props.openingRanges.length) {
    return false;
  }

  const todays = today.value.year(1900);
  return props.openingRanges.reduce((accumulator, currentValue) => {
    if (!accumulator) {
      return dayjs(todays).isBetween(dayjs(currentValue[0]), dayjs(currentValue[1]), 'day', '[]');
    }
    return accumulator;
  }, false);
});

const i18OpenStatus = computed(() => {
  return isCurrentlyOpen.value ? $gettext('currently in operation') : $gettext('currently closed');
});

/********************
 * FUNCTIONS         *
 ********************/

function shortDate(date) {
  return dayjs(date).format('DD.MM.');
}

function isDateInMonth(date, month) {
  return dayjs(dayjs(date)).month() === month - 1;
}

function isBetween(openings, month) {
  const start = dayjs(openings.start).year(1900);
  const end = dayjs(openings.end).year(1900);
  const isEndBeforeStart = end.isBefore(start);
  const inclusiveIndicator = isEndBeforeStart ? '()' : '[]';

  return dayjs(`1900-${month}-01`).isBetween(start, end, 'month', inclusiveIndicator);
}

function isActive(openings, monthAsNumber) {
  const start = dayjs(openings.start).year(1900);
  const end = dayjs(openings.end).year(1900);
  const isEndBeforeStart = end.isBefore(start);
  const isBetweens = isBetween(openings, monthAsNumber);

  return isEndBeforeStart ? !isBetweens : isBetweens;
}

function isEndBeforeStart(start, end) {
  return dayjs(end).isBefore(dayjs(start));
}

function getDisplayDate(start, end, month, idx) {
  const startInMonth = isDateInMonth(start, month);
  const endInMonth = isDateInMonth(end, month);
  const dtStart = shortDate(start);
  const dtEnd = shortDate(end);

  if (startInMonth && endInMonth) {
    if (isEndBeforeStart(start, end)) {
      return idx === 0 ? dtEnd : dtStart;
    } else {
      return idx === 0 ? dtStart : dtEnd;
    }
  } else if (startInMonth) {
    return dtStart;
  } else {
    return dtEnd;
  }
}

function getMonthStyles(openings, monthAsNumber) {
  const startDate = dayjs(openings.start);
  const endDate = dayjs(openings.end);
  const isStart = isDateInMonth(openings.start, monthAsNumber);
  const isEnd = isDateInMonth(openings.end, monthAsNumber);

  let size = 100;
  let styles = {};

  if (isStart && isEnd) {
    const left = 100 - ((startDate.daysInMonth() - (startDate.date() - 1)) / startDate.daysInMonth()) * 100;
    const right = (endDate.date() / endDate.daysInMonth()) * 100;

    if (!endDate.isBefore(startDate)) {
      styles.borderTopRightRadius = '5px';
      styles.borderBottomRightRadius = '5px';
      styles.borderBottomLeftRadius = '5px';
      styles.borderTopLeftRadius = '5px';

      if (viewport.isLessThan('md')) {
        styles.bottom = 'auto';
        styles.top = `${left}%`;
      } else {
        styles.right = 'auto';
        styles.left = `${left}%`;
      }
      size = Math.abs(left - right);
    } else {
      styles.left = '0';
      styles.paddingLeft = `${right}%`;
      styles.paddingRight = `${100 - left}%`;
      styles.background = 'linear-gradient(white, white) content-box, rgba(84, 193, 0, 0.7) padding-box';
    }
  } else if (isStart) {
    styles = { ...monthStartStyles.value };
    size = ((startDate.daysInMonth() - (startDate.date() - 1)) / startDate.daysInMonth()) * 100;
  } else if (isEnd) {
    styles = { ...monthEndStyles.value };
    size = (endDate.date() / endDate.daysInMonth()) * 100;
  }

  if (viewport.isLessThan('md')) {
    styles.height = `${size}%`;
  } else {
    styles.width = `${size}%`;
  }

  return styles;
}
</script>

<template>
  <div
    class="relative flex w-full flex-col divide-y divide-gray-40 overflow-hidden p-0 pr-[30px] md:flex-row md:divide-x md:px-0 md:py-[30px]"
  >
    <div
      v-for="(month, idx) in months"
      :key="month"
      class="month relative flex h-[30px] grow items-end border-x border-gray-40 bg-white first-of-type:border-t last-of-type:!border-b md:h-[100px] md:justify-center md:border-x-0 md:!border-b first-of-type:md:border-l last-of-type:md:!border-r"
    >
      <!-- month -->
      <span class="mx-2">{{ month }}</span>

      <!-- active -->
      <template v-for="(openings, index) in opening">
        <div
          v-if="isActive(openings, idx + 1)"
          :key="`openings.start+${index}`"
          class="active absolute flex flex-col md:flex-row md:items-center md:justify-between"
          :style="{ ...getMonthStyles(openings, idx + 1) }"
        >
          <div
            v-if="isDateInMonth(openings.start, idx + 1)"
            class="start text-small-book pl-2 font-medium md:pl-0"
            :data-date="getDisplayDate(openings.start, openings.end, idx + 1, 0)"
          />
          <div
            v-if="isDateInMonth(openings.end, idx + 1)"
            class="end text-small-book pl-2 font-medium md:pl-0"
            :data-date="getDisplayDate(openings.start, openings.end, idx + 1, 1)"
          />
        </div>
      </template>

      <!-- today -->
      <div
        v-if="isDateInMonth(today, idx + 1)"
        :style="todayStyles"
        class="today absolute m-0 border-b border-dashed p-0 md:border-l"
        :class="[isCurrentlyOpen ? 'today--open border-info' : 'border-danger']"
      >
        <CiTooltip
          :config="tooltipConfig"
          :use-default-styles="false"
          class="tooltip__wrapper absolute right-0 size-0 md:right-auto"
        >
          <span class="hidden">&nbsp;</span>
          <template #tooltip>
            <span
              :style="openStatusStyles"
              :class="[isCurrentlyOpen ? 'text-info' : 'text-danger']"
              class="text-small-book whitespace-nowrap uppercase"
            >{{ i18OpenStatus }}</span>
          </template>
        </CiTooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.month {
  .active {
    @apply left-auto right-[10px] top-auto w-[60%] bg-[rgba(84,193,0,0.7)] content-[''];

    @include media-breakpoint-up(md) {
      @apply left-0 top-[5px] h-[65%] w-full;
    }
  }

  .today {
    @apply right-0 z-20 h-px w-[70%];

    @include media-breakpoint-up(md) {
      @apply top-0 h-[75%] w-px;
    }

    &::before {
      @apply absolute right-0 top-1/2 h-0 w-0 -translate-y-1/2 content-[''];
      border-bottom: 5px solid transparent;
      border-right: 5px solid $danger;
      border-top: 5px solid transparent;

      @include media-breakpoint-up(md) {
        @apply left-1/2 top-0 -translate-x-1/2;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $danger;
      }
    }

    &--open {
      &::before {
        border-right: 5px solid $info;

        @include media-breakpoint-up(md) {
          border-top: 5px solid $info;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
  }
}

.tooltip__wrapper {
  writing-mode: tb-rl;

  @include media-breakpoint-up(md) {
    writing-mode: horizontal-tb;
  }
}

.start,
.end {
  @apply text-dark-green;
  z-index: 14;

  @include media-breakpoint-up(md) {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
  }
}

.start {
  &:before {
    position: relative;
    content: attr(data-date);
  }
}

.end {
  &:after {
    position: relative;
    content: attr(data-date);
  }
}
</style>
