export default function useTextExcerpt() {
  /********************
   * FUNCTIONS         *
   ********************/
  function textExcerpt(value, length = 40) {
    if (!value) {
      return '';
    }

    const temp = value.replace(/\s*\n\s*/g, ' ');
    const splittedValue = temp.split(' ');
    const newValue = [];

    for (let i = 0; i < length; i += 1) {
      newValue.push(splittedValue[i]);
    }

    if (splittedValue.length > length) {
      newValue.push('…');
    }

    return newValue.join(' ');
  }

  /* This function spilt a string at ' ' and returns a new string starting at a given word count */
  function textExcerptStartAt(value, start = 0) {
    if (!value) {
      return '';
    }

    const { length } = value;

    const temp = value.replace(/\s*\n\s*/g, ' ');
    const splittedValue = temp.split(' ');
    const newValue = [];

    for (let i = 0; i < length; i += 1) {
      if (start <= i) {
        newValue.push(splittedValue[i]);
      }
    }

    return newValue.join(' ');
  }

  return {
    textExcerpt,
    textExcerptStartAt,
  };
}
