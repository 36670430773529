<script setup lang="ts">
// components
import CiModalHeader from '@/components/CiModal/CiModalHeader.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiContactModalProps {
  contactData: any;
}
const props = withDefaults(defineProps<CiContactModalProps>(), {});

const emit = defineEmits<{
  close: [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();

const i18nContactTitle = computed(() => {
  return $gettext('Contact');
});
</script>

<template>
  <div class="contact rounded-lg bg-white shadow-2xl">
    <CiModalHeader
      :title="i18nContactTitle"
      :closeable="true"
      @modal-close="emit('close')"
    />
    <div class="px-4 py-6">
      <div class="container-fluid">
        <div class="row">
          <template v-if="props.contactData.phone1 || props.contactData.phone2">
            <div class="col-md-12 text-center lg:text-left">
              <span class="font-medium">{{ $gettext('Phone') }}</span>
            </div>
            <div
              v-if="props.contactData.phone1"
              class="col-md-12 text-center lg:text-left"
            >
              <a
                :href="`tel:${props.contactData.phone1}`"
                class="text-primary"
              >{{ props.contactData.phone1 }}</a>
            </div>
            <div
              v-if="props.contactData.phone2"
              class="col-md-12 text-center lg:text-left"
            >
              <a
                :href="`tel:${props.contactData.phone2}`"
                class="text-primary"
              >{{ props.contactData.phone2 }}</a>
            </div>
          </template>

          <template v-if="props.contactData.fax">
            <div class="col-md-12 mt-6 text-center lg:text-left">
              <span class="font-medium">{{ $gettext('Fax') }}</span>
            </div>
            <div class="col-md-12 text-center lg:text-left">
              {{ props.contactData.fax }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
