<script setup lang="ts">
// icons
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faPhone, faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

// components
import CiCampsiteMeta from '@/components/campsitedetail/CiCampsiteSectionBase/CiCampsiteMeta/CiCampsiteMeta.vue';
import CiFanclubOverview from '@/components/fanclub/CiFanclubOverview.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionBaseProps {
  campsite: any;
  isContentLoading?: boolean;
  isIntegrationProvider: boolean;
  preEnterData?: any;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  scrollToRef: Function;
}
const props = withDefaults(defineProps<CiCampsiteSectionBaseProps>(), {
  isContentLoading: true,
  preEnterData: null,
});

/********************
 * COMPOSITIONS      *
 ********************/
const contentStore = useContentStore();
const localePath = useLocalePath();
const route = useRoute();
const { $device } = useNuxtApp();
const { $gettext, $ngettext, $pgettext } = useGettext();
const { isFavorite, toggleFavorite } = useFavorites({ campsite: () => props.campsite });
const { mapRating } = useRating();
const { onNativeShare, shareTrackingContext } = useCampsiteDetailShare({ campsite: () => props.campsite });
const { openModal, closeModal } = useCiModal();
const { trackLinkClick } = useTracking();

const { ppcWebsiteLink, hasTrafficProduct } = useCampsiteDetail({
  campsite: () => props.campsite,
  preEnterData: () => props.preEnterData,
});

/********************
 * REFS & VARS       *
 ********************/
const scrollableContainerRef = ref<HTMLElement | null>(null);
const scrollableRef = ref<HTMLElement | null>(null);
const { showRightGradient, showLeftGradient } = useScrollable({
  scrollableContainerRef,
  scrollableRef,
});

const campsiteName = computed(() => {
  if (props.campsite?.meta?.name) {
    return props.campsite.meta.name;
  } else if (props.preEnterData?.name) {
    return props.preEnterData.name;
  }
  return props;
});

function onShareClicked(event) {
  if (navigator?.share && $device?.category && ($device.category === 'iOS' || $device.category === 'Android')) {
    onNativeShare(event);
  } else {
    const { id, classList, innerHTML } = event.target;

    openShareModal();

    trackLinkClick({
      targetUrl: 'modal-share',
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: 'modal',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });
  }
}

function openShareModal() {
  const CiShare = defineAsyncComponent({
    loader: () => import('@/components/CiShare/CiShare.vue'),
  });

  openModal(
    {
      component: CiShare,
      attrs: {
        name: 'CiShare',
        campsite: props.campsite,
        onClose: () => {
          closeModal('CiShare');
        },
      },
    },
    {
      clickToClose: true,
      contentClass: 'max-w-[600px] w-full h-auto',
      contentTransition: 'translate-y-down',
    },
  );
}
</script>

<template>
  <div class="container">
    <!-- headline -->
    <h1 class="relative m-0 text-left font-bold md:text-center">
      <template v-if="contentStore.h1">
        {{ contentStore.h1 }}
      </template>
      <template v-else>
        {{ campsiteName }}
      </template>
    </h1>

    <!-- rating / award -->
    <div
      ref="scrollableContainerRef"
      class="relative"
    >
      <div
        ref="scrollableRef"
        class="scrollable mt-4 flex md:justify-center"
      >
        <a
          class="flex items-center hover:no-underline"
          :class="{ 'mr-6': props.campsite.ci_award }"
          href="#rating"
          @click="props.scrollToRef($event, 'rating')"
        >
          <div style="width: 90px" data-nosnippet>
            <CiRatingRadial
              :rating="props.campsite.rating_campsite.rating_avg_overall"
              class="chart-wrapper"
              font-class="text-2xl font-medium"
            />
          </div>
          <div class="ml-2 flex flex-col whitespace-nowrap" data-nosnippet>
            <template v-if="props.campsite.rating_guest.rating_count">
              <span class="font-bold uppercase text-black">{{
                mapRating(props.campsite.rating_campsite.rating_avg_overall)
              }}</span>
              <div class="text-small-book text-primary">
                {{
                  $ngettext('%{count} rating', '%{count} ratings', props.campsite.rating_guest.rating_count, {
                    count: props.campsite.rating_guest.rating_count,
                  })
                }}
              </div>
            </template>
            <div
              v-else
              class="text-small-book text-center"
            >
              {{ $pgettext('Please keep short. Has little space.', 'No ratings yet') }}
            </div>
          </div>
        </a>

        <div
          v-if="props.campsite.ci_award"
          class="flex cursor-pointer items-center"
          @click="props.scrollToRef($event, 'awards')"
        >
          <img
            class="max-w-[90px]"
            src="~/assets/svg/award-logo.svg"
            width="90"
            :alt="`Camping.info Award ${props.campsite.ci_award}`"
          />
          <div class="ml-2 flex flex-col">
            <div class="award-name font-bold uppercase">
              Award
            </div>
            <div class="award-year -mt-1">
              {{ props.campsite.ci_award }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showLeftGradient"
        class="absolute bottom-0 left-0 h-full w-5"
        style="background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 100%)"
      />
      <div
        v-if="showRightGradient"
        class="absolute bottom-0 right-0 h-full w-5"
        style="background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%)"
      />
    </div>

    <!-- cta list -->
    <div class="row mt-12">
      <div class="col-12 col-lg-8 offset-lg-2 mt-2">
        <div class="flex items-center justify-around whitespace-nowrap rounded bg-gray-10 sm:justify-center">
          <a
            v-if="ppcWebsiteLink('top')"
            :href="ppcWebsiteLink('top') || undefined"
            class="text-tiny-book mx-2 flex flex-col items-center truncate py-4 text-dark sm:mx-6 md:flex-row"
            referrerpolicy="unsafe-url"
            target="_blank"
            rel="noopener"
            style="flex-shrink: 1"
          >
            <CiAwesomeIcon
              :icon="faGlobe"
              class="mx-0 mb-2 md:mb-0 md:mr-4"
              ratio="0.8"
            />
            <div class="max-w-full self-start truncate md:self-center">{{ $gettext('Website') }}</div>
          </a>
          <button
            type="button"
            class="button button--link text-tiny-book mx-2 flex flex-col truncate py-4 text-dark sm:mx-6 md:flex-row"
            style="flex-shrink: 1"
            @click="props.scrollToRef($event, 'contact')"
          >
            <CiAwesomeIcon
              :icon="faPhone"
              class="mx-0 mb-2 md:mb-0 md:mr-4"
              ratio="0.8"
            />
            <span class="m-0 max-w-full self-start truncate md:self-center">
              {{ $gettext('Contact') }}
            </span>
          </button>

          <button
            type="button"
            class="button button--link text-tiny-book mx-2 flex flex-col truncate py-4 text-dark sm:mx-6 md:flex-row"
            style="flex-shrink: 1"
            @click="onShareClicked"
          >
            <CiAwesomeIcon
              :icon="faShareNodes"
              class="mx-0 mb-2 md:mb-0 md:mr-4"
              ratio="0.8"
            />
            <span class="m-0 max-w-full self-start truncate md:self-center">
              {{ $gettext('Share') }}
            </span>
          </button>

          <CiCampsiteFavorite
            :active="isFavorite"
            icon-ratio="0.8"
            class="mx-2 py-4 sm:mx-6"
            is-cpdp
            @click="toggleFavorite"
          />
        </div>
      </div>
    </div>

    <!-- availability / inquire cta -->
    <div
      v-if="props.campsite.meta.inquiries_allowed || props.isIntegrationProvider"
      class="row mt-6"
    >
      <div class="col-12 col-lg-6 offset-lg-3">
        <button
          v-if="props.isIntegrationProvider"
          type="button"
          class="button button--primary icon-absolute w-full"
          @click="props.scrollToRef(null, 'availabilities')"
        >
          <span>{{ $gettext('Check availability') }}</span>
          <CiAwesomeIcon
            :icon="faChevronRight"
            ratio="0.6"
          />
        </button>
        <nuxt-link
          v-else-if="props.campsite.meta.inquiries_allowed && !props.isIntegrationProvider"
          class="button button--primary icon-absolute w-full"
          :to="localePath({ name: 'campsite-identifier-inquire', params: route.params, query: route.query })"
        >
          <span>{{ $gettext('Send inquiry') }}</span>
          <CiAwesomeIcon
            :icon="faChevronRight"
            ratio="0.6"
          />
        </nuxt-link>
      </div>
    </div>

    <!-- separator -->
    <div class="row my-6">
      <div class="col-12 col-lg-8 offset-lg-2">
        <hr class="h-px border-0 bg-gray-40" />
      </div>
    </div>

    <!-- meta -->
    <div class="row" data-nosnippet>
      <div class="col-12 col-lg-6 offset-lg-3">
        <CiCampsiteMeta
          :campsite="campsite"
          :has-traffic="hasTrafficProduct"
          @show-map="props.scrollToRef($event, 'location')"
        />
      </div>
    </div>

    <!-- fanclub -->
    <div
      v-if="props.campsite.fanclub?.benefits"
      class="row pt-12"
    >
      <div class="col-12 col-lg-6 offset-lg-3">
        <CiFanclubOverview :items="props.campsite.fanclub.text" />
      </div>
    </div>
  </div>
</template>

<style></style>
