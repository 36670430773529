<script setup lang="ts">
import { isEmpty } from 'ramda';

// components
import CiCampsiteDetailProposeChange from '@/components/campsitedetail/CiCampsiteDetailProposeChange/CiCampsiteDetailProposeChange.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionLocationProps {
  campsite: any;
  campsiteMapData: any;
}
const props = withDefaults(defineProps<CiCampsiteSectionLocationProps>(), {});

const emit = defineEmits<{
  'map-clicked': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { $device } = useNuxtApp();
const { locale } = useI18n();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const planDirections = computed(() => {
  if (!props.campsite || !props.campsite.meta) {
    return '';
  }
  return props.campsite.meta.plain_directions || '';
});

const translationLanguage = computed(() => {
  if (!props.campsite || !props.campsite.meta) {
    return null;
  }

  return props.campsite.meta.translations_lang || null;
});

const latitude = computed(() => {
  return (props.campsite?.meta?.geo_position?.coordinates?.[1] || '').toString();
});

const longitude = computed(() => {
  return (props.campsite?.meta?.geo_position?.coordinates?.[0] || '').toString();
});

const hasAddress = computed(() => {
  return !!(
    !isEmpty(props.campsite?.meta?.street)
    || !isEmpty(props.campsite?.meta?.street_no)
    || !isEmpty(props.campsite?.meta?.zip)
    || !isEmpty(props.campsite?.meta?.city)
  );
});

const hasDirections = computed(() => {
  /* eslint-disable */
  const {
    distance_sea,
    distance_lake,
    distance_river,
    distance_city,
    distance_town,
    distance_station,
    distance_highway_exit,
  } = props.campsite.distances;
  const hasMountainValue = props.campsite.general.is_in_the_mountains !== null;

  return !!(
    props.campsite.meta.directions ||
    hasMountainValue ||
    props.campsite.meta.elevation ||
    distance_sea ||
    distance_lake ||
    distance_river ||
    distance_city ||
    distance_town ||
    distance_station ||
    distance_highway_exit
  );
  /* eslint-enable */
});

const campsiteAddress = computed(() => {
  return encodeURIComponent(
    `${props.campsite?.meta?.street || ''} ${props.campsite?.meta?.street_no || ''} ${
      props.campsite?.meta?.zip || ''
    } ${props.campsite?.meta?.city || ''}`,
  );
});

const mapLink = computed(() => {
  if (!$device) {
    return `http://maps.google.com/maps?daddr=${campsiteAddress.value}`;
  }

  const device = $device.category;
  let link;
  switch (device) {
    case 'Android':
      link = `geo:?q=${campsiteAddress.value}`;
      break;
    case 'iOS':
      link = `maps:?q=${campsiteAddress.value}`;
      break;
    default:
      link = `http://maps.google.com/maps?daddr=${campsiteAddress.value}`;
      break;
  }

  return link;
});

/********************
 * FUNCTIONS         *
 ********************/
function hasDistanceValue(value: any) {
  if (value === undefined) {
    return false;
  }
  return value > 0 || (!value && value !== null);
}
</script>

<template>
  <section data-nosnippet>
    <h2
      key="section-headline-location"
      class="px-6 text-center text-black"
    >
      {{ $gettext('Location') }}
    </h2>

    <!-- map -->
    <CiMapStatic
      v-if="latitude && longitude"
      class="campsite-detail__map-static"
      :campsite="props.campsiteMapData"
      :lat="latitude"
      :lng="longitude"
      @map-static-show-modal="emit('map-clicked')"
    />

    <div class="container mt-12">
      <div
        class="row"
        :class="{ 'justify-center': !hasDirections }"
      >
        <div
          v-if="hasDirections"
          class="col-12 col-lg-8 mb-6 lg:mb-0"
        >
          <CiTextExcerpt
            v-if="props.campsite.meta.directions"
            class="mb-6"
            :excerpt="planDirections"
            :html="props.campsite.meta.directions"
            :language="translationLanguage"
          >
            <template #headline>
              <h3>{{ $gettext('Directions') }}</h3>
            </template>
          </CiTextExcerpt>

          <ul class="m-0 list-none p-0">
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_sea)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Sea') }}</span>:
              <span v-if="props.campsite.distances.distance_sea === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_sea > 0">{{
                new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(
                  props.campsite.distances.distance_sea,
                )
              }}
                km</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_lake)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Lake') }}</span>:
              <span v-if="props.campsite.distances.distance_lake === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_lake > 0">{{
                new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(
                  props.campsite.distances.distance_lake,
                )
              }}
                km</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_river)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('River') }}</span>:
              <span v-if="props.campsite.distances.distance_river === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_river > 0">{{
                new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(
                  props.campsite.distances.distance_river,
                )
              }}
                km</span>
            </li>
            <li
              v-if="props.campsite.general.is_in_the_mountains !== null"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('In the mountains') }}</span>:
              <span v-if="props.campsite.general.is_in_the_mountains">{{ $gettext('Yes') }}</span>
              <span v-else>{{ $gettext('No') }}</span>
            </li>
            <li
              v-if="props.campsite.meta.elevation"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Height (above sea level)') }}</span>:
              <span>{{ props.campsite.meta.elevation }} m</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_city)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('To next city') }}</span>:
              <span v-if="props.campsite.distances.distance_city === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_city > 0">{{ new Intl.NumberFormat(locale, {}).format(props.campsite.distances.distance_city) }} km</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_town)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('To next valley') }}</span>:
              <span v-if="props.campsite.distances.distance_town === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_town > 0">{{ new Intl.NumberFormat(locale, {}).format(props.campsite.distances.distance_town) }} km</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_station)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Train or bus station') }}</span>:
              <span v-if="props.campsite.distances.distance_station === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_station > 0">{{ new Intl.NumberFormat(locale, {}).format(props.campsite.distances.distance_station) }} km</span>
            </li>
            <li
              v-if="hasDistanceValue(props.campsite.distances.distance_highway_exit)"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Motorway departure') }}</span>:
              <span v-if="props.campsite.distances.distance_highway_exit === 0">{{ $gettext('On site') }}</span>
              <span v-if="props.campsite.distances.distance_highway_exit > 0">{{ new Intl.NumberFormat(locale, {}).format(props.campsite.distances.distance_highway_exit) }} km</span>
            </li>
          </ul>
        </div>

        <div :class="{ 'col-auto': !hasDirections, 'col-12 col-lg-4': hasDirections }">
          <template v-if="hasAddress">
            <h3>{{ $gettext('Address') }}</h3>
            <div>
              <div v-if="!isEmpty(props.campsite.meta.street) || !isEmpty(props.campsite.meta.street_no)">
                {{ props.campsite.meta.street }}&nbsp;{{ props.campsite.meta.street_no }}
              </div>
              <template v-if="!isEmpty(props.campsite.meta.zip)">
                {{ props.campsite.meta.zip }}&nbsp;
              </template>
              <template v-if="!isEmpty(props.campsite.meta.city)">
                {{ props.campsite.meta.city }}
              </template>
              <div v-if="!isEmpty(props.campsite.meta.zip) || !isEmpty(props.campsite.meta.city)" />
              {{ props.campsite.meta.country.name }}
            </div>
          </template>

          <template v-if="latitude && longitude">
            <div class="mt-6 font-medium">
              {{ $gettext('GPS-Coordinates') }}
            </div>
            <div class="">
              Lat {{ latitude }}, Long {{ longitude }}
            </div>
          </template>

          <template v-if="hasAddress">
            <a
              :href="mapLink"
              class="button button--link mt-4 text-primary"
              target="_blank"
              rel="noopener"
            >
              {{ $gettext('Route') }}
            </a>
          </template>
        </div>
      </div>

      <CiCampsiteDetailProposeChange
        class="mt-6 block"
        data-tracking-id="location-suggest-change"
      />
    </div>
  </section>
</template>

<style></style>
