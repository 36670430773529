<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteEquipmentListItemDistanceProps {
  value: any;
}
const props = withDefaults(defineProps<CiCampsiteEquipmentListItemDistanceProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const hasOpeningTimes = computed(() => {
  return props.value.openingTimes && props.value.openingTimes.start && props.value.openingTimes.end;
});

/********************
 * FUNCTIONS         *
 ********************/
function beautifulDistance(value) {
  if (!value || !value.unit) {
    return '';
  }

  if (!value.message) {
    return value.unit;
  }

  if (value.message && value.value > 0) {
    return `${value.unit}, ${value.message}`;
  }

  return `${value.message}`;
}
</script>

<template>
  <template v-if="props.value.value > 0 || (props.value.value === 0 && props.value.message) || hasOpeningTimes">
    <span
      v-if="props.value.value > 0 || (props.value.value === 0 && props.value.message)"
      class="text-info"
    >(<span class="whitespace-nowrap"><span v-if="props.value.value > 0">{{ new Intl.NumberFormat(locale).format(Math.round(props.value.value * 100) / 100) }}&nbsp;</span>{{ beautifulDistance(value) }}</span><template v-if="hasOpeningTimes"><span v-if="props.value.value > 0">, </span><span v-if="props.value.openingTimes.start">{{ props.value.openingTimes.start }}</span><span v-if="props.value.openingTimes.end">-{{ props.value.openingTimes.end }}</span></template>)</span>
    <template v-else-if="hasOpeningTimes">
      <span class="text-info">
        <template v-if="props.value.openingTimes.start || props.value.openingTimes.end">(<span v-if="props.value.openingTimes.start">{{ props.value.openingTimes.start }}</span><span v-if="props.value.openingTimes.end">-{{ props.value.openingTimes.end }}</span>)</template>
      </span>
    </template>
  </template>
</template>
