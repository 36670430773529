<script setup lang="ts">
// components
import CiMapMarker from '@/components/map/CiMapMarker/CiMapMarker.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiMapStaticProps {
  campsite: any;
  lng: string;
  lat: string;
}
const props = withDefaults(defineProps<CiMapStaticProps>(), {});

const emit = defineEmits<{
  'map-static-show-modal': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { MAP_STATIC_URL } = useMapSettings();

/********************
 * REFS & VARS       *
 ********************/
const imageJpg = ref<string | null>(null);
const imageWebp = ref<string | null | undefined>(null);

imageJpg.value = MAP_STATIC_URL.replace('{slug}', props.campsite.slug).replace('{size}', 'wide');
imageWebp.value = imageJpg.value;

/********************
 * FUNCTIONS         *
 ********************/
function onClickMap() {
  emit('map-static-show-modal');
}
</script>

<template>
  <div class="hover:cursor-pointer">
    <div
      class="map relative z-10 mt-12 h-[350px] w-full cursor-pointer overflow-hidden"
      style="contain-intrinsic-size: 350px; content-visibility: auto"
      @click="onClickMap"
    >
      <picture>
        <source
          :srcset="imageWebp"
          type="image/webp"
        />
        <source
          :srcset="imageJpg"
          type="image/jpg"
        />
        <img
          class="absolute left-0 top-0 block h-full w-screen object-cover"
          :src="imageJpg"
          loading="lazy"
          alt="map"
        />
      </picture>

      <CiMapMarker
        :campsite="campsite"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full"
      />
    </div>
  </div>
</template>

<style></style>
