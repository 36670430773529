<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiReviewSlideMoreProps {
  count: number;
  campsiteId: string;
  users?: any;
}
const props = withDefaults(defineProps<CiReviewSlideMoreProps>(), {
  users: () => [],
});

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const { $gettext, $ngettext } = useGettext();
</script>

<template>
  <div class="relative flex size-full flex-col">
    <div class="flex w-full justify-center">
      <div class="flex justify-center">
        <CiUserAvatar
          v-for="(user, index) in props.users"
          :key="`${user}-${index}`"
          :user-image="user"
          class="-ml-4 first:ml-0"
          bg-class="bg-white p-1"
          size="xl"
        />
      </div>
    </div>
    <div
      class="mt-[-38px] flex h-full rounded-lg bg-gray-10 px-4 pb-6 pt-[38px]"
      style="box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25)"
    >
      <div class="flex h-full flex-col items-center justify-center">
        <p class="mt-6 text-center font-medium">
          {{
            $ngettext(
              'There is %{ count } review of a camper.',
              'There are %{ count } more reviews of campers.',
              props.count,
              { count: props.count },
            )
          }}
        </p>
        <nuxt-link
          :to="localePath({ name: 'campsite-identifier-reviews', params: { identifier: props.campsiteId } })"
          class="text-primary"
        >
          <span>{{ $gettext('Read all reviews') }}</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style></style>
