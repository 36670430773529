<script setup lang="ts">
// icons
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiTextExcerptProps {
  excerpt: string;
  html?: string;
  language?: string | null;
}
const props = withDefaults(defineProps<CiTextExcerptProps>(), {
  html: '',
  language: null,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();
const { textExcerpt, textExcerptStartAt } = useTextExcerpt();
</script>

<template>
  <section class="description">
    <slot name="headline" />
    <template v-if="props.html !== ''">
      <CiGenericScrollExpand v-slot="{ isExpanded, toggleExpand }">
        <div class="description__excerpt break-words">
          <div
            v-if="isExpanded"
            :lang="props.language || undefined"
            v-html="props.html"
          />
          <div
            v-else
            :lang="props.language || undefined"
            class="inline"
          >
            <span :lang="props.language || undefined">{{ textExcerpt(props.excerpt, 40) }}</span>

            <!-- the rest of the string hidden in markup but visible for SEO -->
            <span
              v-show="false"
              :lang="props.language || undefined"
            >
              {{ textExcerptStartAt(props.excerpt, 40) }}
            </span>
          </div>

          <CiButton
            class="button button--text p-0 text-primary"
            @click="toggleExpand"
          >
            <span class="text-base leading-5">{{ isExpanded ? $gettext('Read less') : $gettext('Read more') }}</span>
            <template #icon>
              <CiAwesomeIcon
                :icon="isExpanded ? faChevronCircleUp : faChevronCircleDown"
                class="fill-primary"
                ratio="0.8"
              />
            </template>
          </CiButton>
        </div>
      </CiGenericScrollExpand>
    </template>

    <template v-else>
      <slot name="fallback" />
    </template>
  </section>
</template>

<style lang="scss" scoped>
.description {
  .description__excerpt {
    :deep(p) {
      &:after {
        content: '   ';
      }
    }
  }
}
</style>
