<script setup lang="ts">
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';

const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiTooltipProps {
  config?: any;
  useDefaultStyles?: boolean;
}
const props = withDefaults(defineProps<CiTooltipProps>(), {
  useDefaultStyles: true,
  config: () => ({
    placement: 'top',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'right'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }),
});

/********************
 * REFS & VARS       *
 ********************/
const popper = ref<Instance | null>(null);
const root = ref<HTMLElement | null>(null);
const tooltipRef = ref<HTMLElement | null>(null);
const triggerRef = ref<HTMLElement | null>(null);

/********************
 * WATCHERS          *
 ********************/
watch(() => props.config, onConfigChange, { deep: true });

onMounted(() => {
  nextTick(() => {
    if (triggerRef.value && tooltipRef.value) {
      popper.value = createPopper(triggerRef.value, tooltipRef.value, props.config);

      if (root.value) {
        showEvents.forEach((event) => {
          root.value!.addEventListener(event, show.bind(this));
        });

        hideEvents.forEach((event) => {
          root.value!.addEventListener(event, hide.bind(this));
        });
      }
    }
  });
});

onBeforeUnmount(() => {
  if (root.value) {
    showEvents.forEach((event) => {
      root.value!.removeEventListener(event, show.bind(this));
    });

    hideEvents.forEach((event) => {
      root.value!.removeEventListener(event, hide.bind(this));
    });
  }

  if (!popper.value) {
    return;
  }

  popper.value.destroy();
});

/********************
 * FUNCTIONS         *
 ********************/

function onConfigChange(newValue) {
  popper.value?.setOptions(newValue);
}

function show() {
  tooltipRef.value?.setAttribute('data-show', '');
  popper.value?.update();
}

function hide() {
  tooltipRef.value?.removeAttribute('data-show');
}
</script>

<template>
  <span ref="root">
    <span ref="triggerRef">
      <slot />
    </span>
    <span
      ref="tooltipRef"
      data-tooltip
      class="z-20"
      :class="{ tooltip: props.useDefaultStyles }"
    >
      <slot name="tooltip" />
      <div
        id="arrow"
        data-popper-arrow
      />
    </span>
  </span>
</template>

<style lang="scss" scoped>
.tooltip {
  background-color: #fefbdd;
  border-radius: $border-radius-lg;
  border: 2px solid $yellow;
  color: $dark;
  display: none;
  padding: 1rem;
  width: auto;
}

.tooltip[data-show] {
  display: block;
}

#arrow {
  position: absolute;
}

#arrow {
  visibility: hidden;
}

#arrow::after,
#arrow::before {
  content: '';
  position: absolute;
  visibility: visible;
  width: 100%;
}

$border-width: 10px;

.tooltip[data-popper-placement^='top'] > #arrow {
  bottom: 0;

  &::after,
  &::before {
    border-left: $border-width solid transparent;
    border-right: $border-width solid transparent;
    margin-left: -$border-width;
    top: 0;
    left: 0;
  }

  &::before {
    border-top: $border-width solid $yellow;
  }

  &::after {
    border-top: $border-width solid #fefbdd;
    margin-top: -2px;
  }
}

.tooltip[data-popper-placement^='bottom'] > #arrow {
  top: 0;

  &::after,
  &::before {
    border-left: $border-width solid transparent;
    border-right: $border-width solid transparent;
    margin-left: -$border-width;
    top: -$border-width + 2;
    left: 0;
  }

  &::before {
    border-bottom: $border-width solid #fefbdd;
    z-index: 1;
  }

  &::after {
    border-bottom: $border-width solid $yellow; // #fefbdd;
    margin-top: -2px;
    z-index: -1;
  }
}

.tooltip[data-popper-placement^='left'] > #arrow {
  right: 0;

  &::after,
  &::before {
    border-top: $border-width solid transparent;
    border-bottom: $border-width solid transparent;
    transform: translateY(-50%);
  }

  &::before {
    border-left: $border-width solid $yellow;
  }

  &::after {
    border-left: $border-width solid #fefbdd;
    margin-left: -2px;
  }
}

.tooltip[data-popper-placement^='right'] > #arrow {
  left: -$border-width;

  &::after,
  &::before {
    border-top: $border-width solid transparent;
    border-bottom: $border-width solid transparent;
    transform: translateY(-50%);
  }

  &::before {
    border-right: $border-width solid $yellow;
  }

  &::after {
    border-right: $border-width solid #fefbdd;
    margin-left: 2px;
  }
}
</style>
