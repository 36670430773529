<script setup lang="ts">
export interface CiCampsiteEquipmentListItemStringProps {
  value: any;
}
const props = withDefaults(defineProps<CiCampsiteEquipmentListItemStringProps>(), {});
</script>

<template>
  <span
    class="text-info"
    :title="props.value.message"
  >({{ props.value.message }})</span>
</template>
