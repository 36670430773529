<script setup lang="ts">
export interface CiCampsiteEquipmentListItemNumberProps {
  value: any;
}
const props = withDefaults(defineProps<CiCampsiteEquipmentListItemNumberProps>(), {});
</script>

<template>
  <span class="text-info">({{ Math.round(props.value * 100) / 100 }})</span>
</template>
