export default function useCampsiteDetailShare({
  campsite: pCampsite = null,
}: {
  campsite: MaybeRefOrGetter<any | null>;
}) {
  const campsite = toRef(pCampsite);

  /********************
   * COMPOSITIONS      *
   ********************/
  const localePath = useLocalePath();
  const route = useRoute();
  const router = useRouter();
  const runtimeConfig = useRuntimeConfig();
  const { $gettext } = useGettext();
  const { trackLinkClick } = useTracking();

  /********************
   * REFS & VARS       *
   ********************/
  const copySuccess = ref(false);
  const baseUrl = (runtimeConfig.public.baseUrl || 'https://www.camping.info').replace(/^\/\//, 'http://');

  const hasClipboardApi = computed(() => {
    return navigator?.clipboard;
  });

  const hasShareApi = computed(() => {
    return !!navigator?.share;
  });

  const i18nHeadline = computed(() => {
    return $gettext('Share this campsite with friends and family');
  });

  const i18nShareText = computed(() => {
    return $gettext('Check out this campsite on camping.info:');
  });

  const i18nShareTextUrlEncoded = computed(() => {
    return encodeURIComponent(i18nShareText.value);
  });

  const i18nShareTextWithoutColon = computed(() => {
    return i18nShareText.value.replace(/\s*:\s*$/, '');
  });

  const shareTrackingContext = computed(() => {
    return [
      {
        schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
        data: {
          booked_products: campsite.value?.purchases || null,
          camping_id: campsite.value?.camping_id,
          civ2_id: campsite.value?.id,
          slug: campsite.value?.slug,
        },
      },
    ];
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function onCopyToClipboard(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    const url = addQueryAndGetURL({
      utm_campaign: 'ci_share_campsite',
      utm_medium: 'social',
      utm_source: 'clipboard',
    });

    navigator.clipboard?.writeText(url).then(() => {
      copySuccess.value = true;
      setTimeout(() => {
        copySuccess.value = false;
      }, 3000);
    });

    trackLinkClick({
      targetUrl: url,
      elementId: id,
      elementClasses: Array.from(classList),
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });
  }

  function onMailShare(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    const query = {
      utm_campaign: 'ci_share_campsite',
      utm_medium: 'social',
      utm_source: 'mail',
    };
    const url = addQueryAndGetURL(query);

    trackLinkClick({
      targetUrl: url,
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: '_blank',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });

    const text = getI18nShareTextAndLink(query);
    const subject = encodeURIComponent(i18nShareTextWithoutColon.value);
    window.open(`mailto:?subject=${subject}&body=${text}`, '_blank');
  }

  function onWhatsAppShare(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    const text = getI18nShareTextAndLink({
      utm_campaign: 'ci_share_campsite',
      utm_medium: 'social',
      utm_source: 'whatsapp',
    });

    trackLinkClick({
      targetUrl: `https://api.whatsapp.com/send?text=${text}`,
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: '_blank',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });

    window.open(`https://api.whatsapp.com/send?text=${text}`, '_blank');
  }

  function onFacebookShare(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    trackLinkClick({
      targetUrl: addQueryAndGetURL({
        utm_campaign: 'ci_share_campsite',
        utm_medium: 'social',
        utm_source: 'facebook',
      }),
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: 'popup',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });

    const url = encodeURIComponent(
      addQueryAndGetURL({
        utm_campaign: 'ci_share_campsite',
        utm_medium: 'social',
        utm_source: 'facebook',
      }),
    );

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${i18nShareTextUrlEncoded.value}`,
      'popup',
      'toolbar=0,status=0,width=580,height=325',
    );
  }

  function onOwnWebsiteShare(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    const url = localePath({
      name: RBN_CAMPSITE_RATING_WIDGET,
      params: route.params,
    });

    trackLinkClick({
      targetUrl: url,
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: '_blank',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });
    window.open(url, '_blank');
  }

  function onNativeShare(event: MouseEvent) {
    const { id, classList, innerHTML } = event.target as HTMLButtonElement;
    const url = addQueryAndGetURL({
      utm_campaign: 'ci_share_campsite',
      utm_medium: 'social',
      utm_source: 'native',
    });

    trackLinkClick({
      targetUrl: url,
      elementId: id,
      elementClasses: Array.from(classList),
      elementTarget: 'native',
      elementContent: innerHTML,
      context: shareTrackingContext.value,
    });

    navigator.share({ url, text: i18nShareText.value });
  }

  function addQueryAndGetURL(query = {}) {
    const newRoute = router.resolve({
      name: route.name || undefined,
      params: route.params,
      query: { ...route.query, ...query },
    });
    return new URL(newRoute?.href, baseUrl).toString();
  }

  function getI18nShareTextAndLink(query) {
    return encodeURIComponent(`${i18nShareText.value} ${addQueryAndGetURL(query)}`);
  }

  return {
    copySuccess,
    hasClipboardApi,
    hasShareApi,
    i18nHeadline,
    i18nShareText,
    i18nShareTextUrlEncoded,
    i18nShareTextWithoutColon,
    onCopyToClipboard,
    onFacebookShare,
    onMailShare,
    onNativeShare,
    onOwnWebsiteShare,
    onWhatsAppShare,
    shareTrackingContext,
  };
}
