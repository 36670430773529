<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiPriceTagProps {
  priceMainSeason?: number;
  priceOffSeason?: number;
}
const props = withDefaults(defineProps<CiPriceTagProps>(), {
  priceMainSeason: 0,
  priceOffSeason: 0,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();
const { getDecimalSeparator } = usePriceFormatter();

/********************
 * REFS & VARS       *
 ********************/
const comparePrice = computed(() => {
  if (hasPrice(props.priceOffSeason)) {
    return props.priceOffSeason;
  }

  if (hasPrice(props.priceMainSeason)) {
    return props.priceMainSeason;
  }

  return null;
});

const comparePriceNumberBeforeComma = computed(() => {
  if (!comparePrice.value) {
    return null;
  }

  const decimal = parseFloat(comparePrice.value.toString()).toFixed(2);
  return decimal.split('.')[0];
});

const comparePriceDecimals = computed(() => {
  if (!comparePrice.value) {
    return null;
  }

  const decimal = parseFloat(comparePrice.value.toString()).toFixed(2);
  return decimal.split('.')[1];
});

const decimalSeparator = computed(() => {
  return comparePrice.value && getDecimalSeparator(comparePrice.value);
});

/********************
 * FUNCTIONS         *
 ********************/
function hasPrice(price: number | null) {
  return price && price !== 0;
}
</script>

<template>
  <div
    v-if="comparePrice"
    class="rounded bg-primary p-4 text-white"
  >
    <div class="flex flex-row justify-center">
      <span class="text-small-book mb-1 mr-2 self-end sm:mb-1">{{ $gettext('From') }}</span>
      <span class="mb-0 text-4xl leading-9">
        <span>{{ comparePriceNumberBeforeComma }}</span><span>{{ decimalSeparator }}</span>
      </span>
      <span class="h-9 leading-7">
        {{ comparePriceDecimals }}
      </span>
      <span class="h-9mb-0 ml-1 text-4xl leading-9">€</span>
    </div>
  </div>
</template>

<style></style>
