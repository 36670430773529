<script setup lang="ts">
// icons
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import translationFixtures from '@/locales/fixtures';

const TOP_ACTIVITY_COUNT = 3;

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteDetailRatingsProps {
  activities: any;
  suggestedGuests: any;
  hasAds?: boolean;
}
const props = withDefaults(defineProps<CiCampsiteDetailRatingsProps>(), {
  hasAds: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext, $ngettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const maxActivityRecommendations = ref(11);

const hasActivities = computed(() => {
  return props.activities?.length;
});

const hasSuggestions = computed(() => {
  return filteredSuggestions.value?.length;
});

const filteredSuggestions = computed(() => {
  return props.suggestedGuests.filter(suggestion => suggestion.value !== null);
});

const topActivities = computed(() => {
  return props.activities ? props.activities.slice(0, TOP_ACTIVITY_COUNT) : [];
});

const i18nRatedByGuestHeadline = computed(() => {
  return $gettext('<strong>Rated by guests</strong> as "Suitable for"', true);
});

/********************
 * FUNCTIONS         *
 ********************/
function getActivityIcon(activity) {
  let icon = '';
  switch (activity.name.toLowerCase()) {
    case 'diving':
      icon = 'diving';
      break;
    case 'hiking':
      icon = 'hiking';
      break;
    case 'swimming':
      icon = 'swimmer';
      break;
    case 'relaxing':
      icon = 'relaxing';
      break;
    case 'cycling':
      icon = 'cycling';
      break;
    case 'tennis':
      icon = 'tennis';
      break;
    case 'fishing':
      icon = 'fishing';
      break;
    case 'kayak':
      icon = 'kayak';
      break;
    case 'sailing':
      icon = 'seiling';
      break;
    case 'mountainbiking':
      icon = 'mountain_biking';
      break;
    case 'motorcycle':
      icon = 'motor';
      break;
    case 'surfing':
      icon = 'surfing';
      break;
    case 'sightseeing':
      icon = 'sightseeing';
      break;
    case 'ski':
      icon = 'ski';
      break;
    case 'wellness':
      icon = 'wellnes';
      break;
    case 'crosscountry':
      icon = 'hiking';
      break;
    case 'culture':
      icon = 'culture';
      break;
    case 'horsebackriding':
      icon = 'horse';
      break;
    case 'paragliding':
      icon = 'paragliding';
      break;
    case 'party':
      icon = 'party';
      break;
    case 'strolling':
      icon = 'strolling';
      break;
    case 'golf':
      icon = 'golf';
      break;
    case 'culinary':
      icon = 'culinary';
      break;
    case 'motorboat':
      icon = 'motorboat';
      break;
    case 'climbing':
      icon = 'climbing';
      break;
    default:
      icon = false;
      break;
  }
  return icon;
}

function getActivityName(activity) {
  return $gettext(translationFixtures[activity.name.toLowerCase()]);
}

function getOtherActivities(isExpanded) {
  if (isExpanded) {
    return props.activities?.slice(TOP_ACTIVITY_COUNT) || [];
  }

  const maxActivities
    = props.activities?.length < maxActivityRecommendations.value
      ? props.activities.length
      : maxActivityRecommendations.value;
  return props.activities?.slice(TOP_ACTIVITY_COUNT, maxActivities) || [];
}
</script>

<template>
  <div
    v-if="hasActivities || hasSuggestions"
    :class="{ 'mt-12': !props.hasAds }"
  >
    <!-- activities -->
    <template v-if="hasActivities">
      <CiGenericScrollExpand v-slot="{ isExpanded, toggleExpand }">
        <div class="container pt-12">
          <div class="row">
            <div class="col-12 text-center">
              <h3 v-html="$gettext('<strong>Activities recommended</strong> by guests', true)" />
            </div>
          </div>
          <div class="row mt-4">
            <!-- top activities -->
            <div
              v-if="topActivities.length"
              class="col-12"
            >
              <div class="row justify-center">
                <div
                  v-for="(activity, index) in topActivities"
                  :key="`top-activities-${index}`"
                  class="col"
                >
                  <div class="flex flex-col items-center">
                    <img
                      loading="lazy"
                      :src="`/assets/img/activities/icon_${getActivityIcon(activity)}.svg`"
                      class="mx-auto"
                      :alt="activity?.name"
                      width="80"
                      height="80"
                      style="width: 80px; max-width: 100%"
                    />
                    <span class="mt-2">{{ getActivityName(activity) }}</span>
                    <span class="text-small-book text-gray">
                      ({{ activity.ratings_activity }} {{ $ngettext('Visitor', 'Visitors', activity.ratings_activity) }})
                    </span>
                  </div>
                </div>
              </div>
              <hr class="my-6 h-px border-0 bg-gray-40" />
            </div>
            <!-- other activities -->
            <div class="col-12">
              <div class="row">
                <div
                  v-for="(activity, index) in getOtherActivities(isExpanded)"
                  :key="`other-activity-${index}`"
                  class="col-6 col-md-3 mb-6"
                >
                  <div class="flex items-start justify-start">
                    <img
                      v-if="getActivityIcon(activity)"
                      loading="lazy"
                      :src="`/assets/img/activities/icon_${getActivityIcon(activity)}.svg`"
                      :alt="activity?.name"
                      width="30"
                      height="30"
                      style="width: 30px; max-width: 100%"
                    />
                    <div class="ml-2 flex flex-col overflow-hidden">
                      <div
                        class="line-clamp-2 break-all"
                        :title="getActivityName(activity)"
                      >
                        {{ getActivityName(activity) }}
                      </div>
                      <span class="text-small-book text-gray">({{ activity.ratings_activity }}
                        <span>{{ $ngettext('Visitor', 'Visitors', activity.ratings_activity) }}</span>)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activities.length > maxActivityRecommendations"
            class="text-center"
          >
            <CiButton
              class="button button--text text-primary"
              @click="toggleExpand"
            >
              <span>{{
                isExpanded ? $gettext('Show less recommendations') : $gettext('Show more recommendations')
              }}</span>
              <template #icon>
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="isExpanded ? faChevronUp : faChevronDown"
                  ratio="0.5"
                />
              </template>
            </CiButton>
          </div>
        </div>
      </CiGenericScrollExpand>
    </template>

    <!-- suggested for -->
    <template v-if="hasSuggestions">
      <div class="container mt-12 pt-12">
        <div class="row">
          <div class="col-12 text-center">
            <h3
              class="font-normal"
              v-html="i18nRatedByGuestHeadline"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div
            v-for="(guestRating, index) in filteredSuggestions"
            :key="index"
            class="col-12 col-sm-6 col-lg-3 mb-6"
          >
            <div class="flex h-full items-center overflow-hidden">
              <div class="shrink-0">
                <CiRatingRadial
                  :rating="parseFloat(guestRating.value)"
                  font-class="font-medium"
                  style="width: 48px; max-width: 100%"
                />
              </div>
              <div
                class="ml-2"
                :title="guestRating.title"
              >
                {{ guestRating.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style></style>
