<script setup lang="ts">
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { getOffsetTop } from '@/utils/general';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiFanclubOverviewProps {
  items: any;
}
const props = withDefaults(defineProps<CiFanclubOverviewProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext, interpolate } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const numBenefits = computed(() => {
  return props.items.length;
});

const showAllBenefitsText = computed(() => {
  const translated = $gettext('show all %{numBenefits} benefits');
  return interpolate(translated, { numBenefits: numBenefits.value });
});

const i18nFanClubHeadline = computed(() => {
  return $gettext('Your <strong class="text-uppercase">Fanclub</strong> Benefits', true);
});

/********************
 * FUNCTIONS         *
 ********************/
function onFanclubLinkClicked(event: MouseEvent) {
  const ele = document.getElementById('fanclub');
  if (!ele) {
    return;
  }
  event.preventDefault();
  window.scroll({ top: getOffsetTop(ele) - 30, behavior: 'smooth' });
}
</script>

<template>
  <div class="fanclub-overview">
    <div
      class="inner relative z-10 overflow-hidden rounded-[0_0_8px_8px] before:absolute before:left-1/2 before:top-[95px] before:-z-1 before:h-40 before:w-[250%] before:-translate-x-1/2 before:bg-primary before:content-[''] md:before:w-[220%]"
    >
      <div class="flex justify-center">
        <img
          src="~/assets/svg/fanclub-logo.svg"
          width="130"
          height="130"
          loading="lazy"
          alt="Fanclub logo"
        />
      </div>

      <div class="main min-h-40 bg-dark-green pb-1 pt-4 text-white">
        <h3
          class="mb-2 px-2 text-center"
          v-html="i18nFanClubHeadline"
        />

        <ul class="m-0 mb-2 list-['✓'] pt-1">
          <li
            v-for="(item, idx) of props.items.slice(0, 3)"
            :key="idx"
            class="px-2"
          >
            {{ item }}
          </li>
        </ul>

        <div
          v-if="numBenefits > 3"
          class="pb-4 pl-2"
        >
          <a
            href="#fanclub"
            class="button button--text text-base font-normal text-white hover:!underline"
            @click="onFanclubLinkClicked($event)"
          >
            <span>... {{ showAllBenefitsText }}</span>
            <CiAwesomeIcon
              class="fill-white"
              :icon="faChevronRight"
              ratio="0.6"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.fanclub-overview {
  .inner {
    &:before {
      border-radius: 50%;
      border-top-left-radius: 60%;
    }

    &:after {
      @apply absolute left-1/2 top-[110px] -z-1 h-40 w-[250%] -translate-x-1/2 bg-dark-green content-[''] md:w-[220%];
      border-radius: 50%;
    }
  }
}
</style>
