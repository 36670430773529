<script setup lang="ts">
// utils
import { getOffsetTop, isInViewport } from '@/utils/general';

const isExpanded = ref(false);
const offsetBeforeCollapse = ref(null);

function onClick($event: MouseEvent) {
  // save position before collapse
  if (isExpanded.value) {
    offsetBeforeCollapse.value = $event.currentTarget.getBoundingClientRect().top;
  }

  isExpanded.value = !isExpanded.value;

  // after collapsed
  if (!isExpanded.value) {
    // wait until collapsed
    nextTick(() => {
      if (!isInViewport($event.currentTarget)) {
        window.scroll({ top: getOffsetTop($event.currentTarget) - offsetBeforeCollapse.value });
      }
    });
  }
}
</script>

<template>
  <div>
    <slot
      :is-expanded="isExpanded"
      :toggle-expand="onClick"
    />
  </div>
</template>

<style></style>
