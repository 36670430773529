<script setup lang="ts">
// icons
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

// components
import CiCampsiteEquipmentListItemDistance from './CiCampsiteEquipmentListItems/CiCampsiteEquipmentListItemDistance.vue';
import CiCampsiteEquipmentListItemNumber from './CiCampsiteEquipmentListItems/CiCampsiteEquipmentListItemNumber.vue';
import CiCampsiteEquipmentListItemString from './CiCampsiteEquipmentListItems/CiCampsiteEquipmentListItemString.vue';

// utils
import { sortArrayOfObjectsByKeyAlphabetically } from '@/utils/general';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteEquipmentListProps {
  items: any;
}
const props = withDefaults(defineProps<CiCampsiteEquipmentListProps>(), {});

/********************
 * REFS & VARS       *
 ********************/
const sortedItems = computed(() => {
  const itemsAsArray = [];
  for (const [key, value] of Object.entries(toRaw(props.items))) {
    itemsAsArray.push({ key, value });
  }
  return sortArrayOfObjectsByKeyAlphabetically('key')(itemsAsArray);
});

/********************
 * FUNCTIONS         *
 ********************/
function getListItemComponent(value) {
  value = toRaw(value);
  let component;
  const type = getListItemType(value);

  if (type === 'distance') {
    return CiCampsiteEquipmentListItemDistance;
  }

  if (type === 'number') {
    component = CiCampsiteEquipmentListItemNumber;
  }

  if (type === 'string') {
    component = CiCampsiteEquipmentListItemString;
  }

  return component;
}

function getListItemType(value) {
  value = toRaw(value);
  const type = typeof value;
  if (type === 'object' && value && value.type && value.type === 'distance') {
    return 'distance';
  }

  if (type === 'object' && value && value.type && value.type === 'string') {
    return 'string';
  }

  if (type === 'number') {
    return 'number';
  }

  if (type === 'boolean') {
    return 'boolean';
  }

  return 'unkown';
}

function isAvailable(value) {
  value = toRaw(value);
  const type = getListItemType(value);

  if (type === 'boolean' && value === false && value !== null) {
    return false;
  }

  if (type === 'number' && !value) {
    return false;
  }

  return !(type === 'distance' && value && (value.value === false || value.value === -1));
}

function isSet(value) {
  value = toRaw(value);
  return value !== null;
}
</script>

<template>
  <div class="container">
    <ul class="m-0 list-none p-0 lg:columns-3">
      <li
        v-for="(item, index) of sortedItems"
        :key="`date-${index}`"
      >
        <div
          v-if="isSet(item.value)"
          class="inline-flex px-2 py-1"
        >
          <CiAwesomeIcon
            :icon="isAvailable(item.value) ? faCheck : faTimes"
            class="mr-2 mt-[2px]"
            :class="[isAvailable(item.value) ? 'fill-primary' : 'fill-danger']"
            ratio="0.75"
          />
          <div>
            <span
              :title="item.key"
              :class="{
                'text-[#adb5bd]': !isSet(item.value) || !isAvailable(item.value),
                'order-2': getListItemType(item.value) === 'boolean',
              }"
            >{{ item.key }}</span>
            <span
              v-if="getListItemType(item.value) !== 'boolean' && isAvailable(item.value)"
              class="ml-1"
            />
            <component
              :is="getListItemComponent(item.value)"
              v-if="getListItemType(item.value) !== 'boolean' && isAvailable(item.value)"
              :value="item.value"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style></style>
