<script setup lang="ts">
import { isEmpty } from 'ramda';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteMetaProps {
  campsite: any;
  hasTraffic?: boolean;
}
const props = withDefaults(defineProps<CiCampsiteMetaProps>(), {
  hasTraffic: false,
});

const emit = defineEmits<{
  'show-map': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/

const { hasOperatingHours, openingDatesAsDates, isCurrentlyOpen } = useCampsiteDetailOperatingHours({
  campsite: () => props.campsite,
});
const backendStore = useBackendStore();
const localePath = useLocalePath();
const { $gettext } = useGettext();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const hasAddress = computed(() => {
  return !!(
    !isEmpty(props.campsite?.meta?.street)
    || !isEmpty(props.campsite?.meta?.street_no)
    || !isEmpty(props.campsite?.meta?.zip)
    || !isEmpty(props.campsite?.meta?.city)
  );
});

const websiteLink = computed(() => {
  return `${backendStore.url}/external-link/?lang=${locale.value}&slug=${props.campsite.slug}&link_identifier=campsite-detail-top`;
});

const websiteName = computed(() => {
  return new URL(props.campsite.meta?.website)?.host;
});
</script>

<template>
  <ul class="m-0 list-none p-0 px-4">
    <li
      v-if="hasOperatingHours"
      class="m-0 p-0 pb-1"
    >
      <span class="font-medium">{{ $gettext('Operating hours') }}</span>:
      <span
        v-for="(dates, idx) in openingDatesAsDates"
        :key="idx"
      >
        <span v-if="idx > 0">, </span>
        <span>{{ dates.start.format('DD.MM') }} - {{ dates.end.format('DD.MM') }}</span>
      </span>
      -
      <span
        v-if="isCurrentlyOpen"
        class="text-small-book uppercase text-info"
      >{{
        $gettext('currently in operation')
      }}</span>
      <span
        v-else
        class="text-small-book uppercase text-danger"
      >{{ $gettext('currently closed') }}</span>
    </li>
    <li
      v-if="hasAddress"
      class="m-0 flex flex-wrap p-0 pb-1"
    >
      <span class="font-medium">{{ $gettext('Address') }}</span>:&nbsp;
      <span v-if="props.campsite.meta.street && !isEmpty(props.campsite.meta.street)">{{
        props.campsite.meta.street
      }}</span><span v-if="props.campsite.meta.street_no && !isEmpty(props.campsite.meta.street_no)">&nbsp;{{ props.campsite.meta.street_no }}</span><span
        v-if="
          (props.campsite.meta.street && !isEmpty(props.campsite.meta.street))
            || (props.campsite.meta.street_no && !isEmpty(props.campsite.meta.street_no))
        "
      >,&nbsp;</span>
      <span v-if="props.campsite.meta.zip && !isEmpty(props.campsite.meta.zip)">{{ props.campsite.meta.zip }}&nbsp;</span>
      <span v-if="props.campsite.meta.city && !isEmpty(props.campsite.meta.city)">{{ props.campsite.meta.city }},&nbsp;</span>
      <span v-if="props.campsite.meta.name && !isEmpty(props.campsite.meta.country.name)">{{
        props.campsite.meta.country.name
      }}</span>&nbsp;-&nbsp;<a
        href="#"
        @click.prevent="emit('show-map')"
      ><span>{{ $gettext('Show on map') }}</span></a>
    </li>
    <li
      v-if="props.campsite.regions.length"
      class="m-0 flex flex-wrap p-0 pb-1"
    >
      <span class="font-medium">{{ $gettext('Region') }}</span>:&nbsp;
      <template
        v-for="region in props.campsite.regions"
        :key="region.slugs[locale]"
      >
        <nuxt-link
          class="campsite-detail-meta__region-link mr-1 after:text-dark after:content-[','] last:after:content-none"
          :to="
            localePath({
              name: RBN_REGION_SEARCH,
              params: {
                regionSlug: region.slugs[locale],
              },
            })
          "
        >{{ region.name }}</nuxt-link>
      </template>
    </li>
    <li
      v-if="hasTraffic && props.campsite.meta.website"
      class="m-0 overflow-hidden truncate p-0"
    >
      <span class="font-medium">{{ $gettext('Website') }}</span>:
      <a
        :href="websiteLink"
        class="text-black"
        referrerpolicy="unsafe-url"
        target="_blank"
        rel="noopener"
      >{{
        websiteName
      }}</a>
    </li>
  </ul>
</template>

<style></style>
