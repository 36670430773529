<script setup lang="ts">
// utils
import dayjs from '@/utils/day';
import { fixImageUrl } from '@/utils/general';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiReviewSlideProps {
  campsiteId: string;
  review: any;
}
const props = withDefaults(defineProps<CiReviewSlideProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const localePath = useLocalePath();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const getDateOfStay = computed(() => {
  if (props.review?.date_of_stay) {
    return dayjs(props.review.date_of_stay).format('MMM YYYY');
  }
  return false;
});

const commentLang = computed(() => {
  if (props.review?.used_lang) {
    return props.review.used_lang;
  }
  if (props.review?.comment_lang_iso2) {
    return props.review.comment_lang_iso2.toLowerCase();
  }
  return null;
});

const content = '\'"\'';
</script>

<template>
  <div class="review-slide relative flex size-full flex-col">
    <div class="flex w-full justify-center">
      <CiUserAvatar
        :user-image="fixImageUrl(backendStore.url, props.review.user_profile_picture)"
        class="z-10"
        bg-class="bg-white p-1"
        size="xl"
      />
      <div class="z-20" style="width: 76px; margin-left: -0.75rem">
        <CiRatingRadial
          :rating="parseInt(props.review.rating_overall, 10)"
          class="chart-wrapper chart-wrapper--border-md w-full"
          font-class="h5"
        />
      </div>
    </div>
    <div class="mt-[-38px] h-full rounded-lg bg-gray-10 px-4 pb-6 pt-[30px] shadow-[0_1px_3px_rgba(0,0,0,0.25)]">
      <div class="row mt-2">
        <div class="col-12 text-center">
          <div class="mt-2 truncate font-medium">
            {{ review.user_display_name }}
          </div>
          <div v-if="getDateOfStay" class="text-info">
            <span>{{ $gettext('Stay') }}</span>: {{ getDateOfStay }}
          </div>
        </div>
      </div>
      <div class="row mt-6">
        <div :lang="commentLang" class="col-12">
          <nuxt-link
            class="relative line-clamp-4 text-center text-dark hover:no-underline"
            :to="localePath({
              name: 'campsite-identifier-reviews-id',
              params: { identifier: props.campsiteId, id: props.review.id },
            })
            "
          >
            <span
              class="after:absolute after:bottom-0 after:right-0 after:content-[var(--content)]"
              :style="`--content: ${content}`"
            >
              "<span class="font-medium">{{ props.review.title }} - </span><span>{{ props.review.comment }}</span>
            </span>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
